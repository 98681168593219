import {UserTopNav} from "./lib/nav/UserTopNav";
import {Route, Routes} from "react-router-dom";
import {LoginPage} from "./pages/LoginPage";
import {LogoutPage} from "./pages/LogoutPage";
import {CustomerOrAdminDashboardPage} from "./pages/CustomerOrAdminDashboardPage";
import {TransactionsPage} from "./Transactions/TransactionsPage";
import {AgentProfilePage} from "./lib/agent/AgentProfilePage";
import {Fragment, ReactNode, useState} from "react";
import {CreateAgentPage} from "./pages/CreateAgentPage";
import {AgentReferralAgreementPage} from "./pages/AgentReferralAgreementPage";
import {SignUpPage} from "./pages/SignUpPage";
import {TryulaAdminsPage} from "./pages/TryulaAdminsPage";
import {useCurrentUser} from "./features/auth/useUserData";
import {UserLeftNav} from "./lib/nav/UserLeftNav";
import {SiteSettingsPage} from "./pages/SiteSettingsPage";
import {AgentSearchPage} from "./pages/AgentSearchPage";
import {CustomersPage} from "./pages/CustomersPage";
import {SuperAdminsPage} from "./pages/SuperAdminsPage";
import {AgentOptOutPage} from "./pages/AgentOptOutPage";
import {AgentsPage} from "./pages/AgentsPage";
import {PageNotFound} from "./lib/styled-components/PageNotFound";
import {CreateTransactionManuallyPage} from "./pages/CreateTransactionManuallyPage";
import {enableCreateTransactionsForSuperAdmin} from "./config/siteSettings";

function createTransactionsPageRouteIfEnabled(): ReactNode {
  if (enableCreateTransactionsForSuperAdmin) {
    return <Route path="/create-transaction" element={<CreateTransactionManuallyPage/>}/>
  }

  return <Fragment/>;
}

export function UserApp() {
  const {user} = useCurrentUser();
  const [isLeftNavOpen, setIsLeftNavOpen] = useState(false)

  const marginToProtectContentFromLeftNav =
    !!user && user.user_type !== 'Customer'
      ? ' ml-8 lg:ml-72'
      : '';
  return <div className="flex">

    <UserLeftNav user={user}
                 isLeftNavOpen={isLeftNavOpen}
                 setIsLeftNavOpen={setIsLeftNavOpen}/>

    <main
      onClick={() => setIsLeftNavOpen(false)}
      className={`bg-white flex flex-1 overflow-x-hidden${marginToProtectContentFromLeftNav}`}>
      <div className="mx-auto w-[1048px] max-w-full p-4 md:p-6 flex flex-col">
        <UserTopNav/>
        <div className="mt-3">
          <Routes>
            {/* Everyone */}
            <Route path="/" element={<AgentSearchPage/>}/>
            <Route path="/login" element={<LoginPage/>}/>
            <Route path="/sign-up" element={<SignUpPage/>}/>
            <Route path="/logout" element={<LogoutPage/>}/>
            <Route path="/agent/:agentId" element={<AgentProfilePage/>}/>

            {/* Customer and Admin */}
            <Route path="/dashboard" element={<CustomerOrAdminDashboardPage/>}/>

            {/* Agent and Admins */}
            <Route path="/transactions" element={<TransactionsPage/>}/>

            {/* Admin and Tryula Admin */}
            <Route path="/agents" element={<AgentsPage/>}/>
            <Route path="/create-agent" element={<CreateAgentPage/>}/>
            <Route path="/customers" element={<CustomersPage/>}/>
            {createTransactionsPageRouteIfEnabled()}

            {/* Super Admin only */}
            <Route path="/tryula-admins" element={<TryulaAdminsPage/>}/>
            <Route path="/super-admins" element={<SuperAdminsPage/>}/>
            <Route path="/site-settings" element={<SiteSettingsPage/>}/>

            {/* Agent */}
            <Route path="/archived-transactions" element={<TransactionsPage view="archived"/>}/>
            <Route path="/referral-agreement" element={<AgentReferralAgreementPage/>}/>
            <Route path="/opt-out" element={<AgentOptOutPage/>}/>

            {/* 404 Not Found */}
            <Route path="*" element={<PageNotFound/>}/>
          </Routes>
        </div>
      </div>
    </main>
  </div>;
}
