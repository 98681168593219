import {FilterState} from "../filter/types";


import {predicateFrom} from "../filter/predicateFrom";

type ListProps = {
  items: HasNumericId[],
  itemComponent: any,
  resourceName: string,
  filter?: FilterState
};

export const List = ({items, itemComponent: ItemComponent, resourceName, filter}: ListProps) =>
  <>
    {items.filter(predicateFrom(filter)).map((item) => <ItemComponent key={item.id} {...{[resourceName]: item}}/>)}
  </>;

export type HasNumericId = { id: number };