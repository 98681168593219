import {FieldWithLabelConfig} from "../johncornish/components/v1/form/types";

export interface TryulaAdmin {
  id: number;
  email: string;
}

export interface SuperAdmin {
  id: number;
  email: string;
}

export type TryulaAdminState = { email: string };
export type SuperAdminState = { email: string };

export const CreateTryulaAdminFields: FieldWithLabelConfig[] = [['Email', 'email', {className: "rounded border border-stroke bg-[#eff4fb] p-1 font-normal text-black focus:border-primary focus-visible:outline-none"}]];