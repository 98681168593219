import {SuperAdmin, SuperAdminState} from "../lib/object-control/admin";
import {
  useCreateSuperAdminMutation,
  useDeleteSuperAdminMutation,
  useGetAllSuperAdminsQuery
} from "../services/tryulaApi";
import {useEffect, useState} from "react";
import {RedError} from "../lib/styled-components/RedError";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {DefaultPageLayout} from "../lib/layouts/DefaultPageLayout";
import {APIUserListPage} from "../lib/johncornish/components/v1/list/APIUserListPage";
import {BasicObjectUpdatingForm} from "../lib/johncornish/components/v1/form/BasicObjectUpdatingForm";
import {GreenCheckmark} from "../lib/styled-components/GreenCheckmark";
import {useCurrentUser} from "../features/auth/useUserData";
import {allow} from "../lib/auth/allow";
import {DeleteWithConfirmation} from "../lib/styled-components/DeleteWithConfirmation";

export function SuperAdminsPage() {
  const [adminState, setAdminState] = useState<SuperAdminState>({
    email: ''
  });

  const {
    data: superAdmins,
    error,
    isLoading,
  } = useGetAllSuperAdminsQuery();

  const {user} = useCurrentUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Admin'), []);

  return <>
    <PageTitle title="Super Admins"/>
    <div
      className="task relative flex justify-between rounded-sm border border-stroke bg-white p-5 shadow-default flex-col gap-5">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col md:flex-row">
          <PageTitle title="Create Super Admin"/>
          <BasicObjectUpdatingForm
            resourceName={"admin"}
            state={adminState}
            setState={setAdminState}
            fieldsWithLabels={[['Email', 'email', {className: "rounded border border-stroke bg-[#eff4fb] p-1 font-normal text-black focus:border-primary focus-visible:outline-none"}]]}
            useMutator={useCreateSuperAdminMutation}
            validate={[
              ['email', state => !!state.email, 'Email is required']
            ]}
            saveText={"Create Super Admin"}
            saveClassName={"ml-1 mt-2 bg-[#27AAE1] hover:bg-blue-500 text-white font-bold py-1 px-4 rounded"}
            errorElement={RedError}
            updateSuccessElement={GreenCheckmark}/>
        </div>
      </div>
    </div>
    <APIUserListPage
      items={superAdmins}
      error={error}
      isLoading={isLoading}
      resourcePlural="Super admins"
      resourceSingular="superAdmin"
      pageLayoutComponent={DefaultPageLayout}
      itemComponent={SuperAdminElement}
      emptyResultComponent={() => <p>No super admins found.</p>}
    />
  </>;
}

const SuperAdminElement = ({superAdmin}: { superAdmin: SuperAdmin }) => {
  const [deleteSuperAdmin] = useDeleteSuperAdminMutation();
  const [deleteError, setDeleteError] = useState('');

  return <div data-testid="super-admin" className="grid grid-cols-3">
    <div className="col-span-1">
    <span data-testid="super-admin-name" className="break-words">
      {superAdmin.email}
    </span>
    </div>

    <div className="col-span-2 items-end">
      <DeleteWithConfirmation dataObject={superAdmin} deleteMutator={deleteSuperAdmin} setDeleteError={setDeleteError}
                              uniqueIdentifier={superAdmin.email}/>
    </div>
    {!!deleteError && <RedError>{deleteError}</RedError>}
  </div>
};
