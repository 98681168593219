import {TwoChildren} from "./types";

export const BasicFormRow = ({children}: TwoChildren) => {
  const [label, field] = children;
  return <>
    {label}:
    <br/>
    {field}
    <br/>
  </>
};