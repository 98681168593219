import {useParams, useSearchParams} from "react-router-dom";
import {useCurrentUser} from "../../features/auth/useUserData";
import {CustomerAgentProfile} from "./CustomerAgentProfile";
import {useGetAgentByIdQuery, useGetAllLanguagesQuery, useGetAllServiceAreasQuery} from "../../services/tryulaApi";
import {RedError} from "../styled-components/RedError";
import {UpdateAgentProfile} from "./UpdateAgentProfile";
import {CreateOrEditAgent} from "./CreateOrEditAgent";
import {PageTitle} from "../johncornish/components/v1/screen/PageTitle";
import {AgentNotFound} from "../styled-components/AgentNotFound";

export interface AgentProfileProps {
  agentId: string;
}

export const AgentProfilePage = () => {
  const {user} = useCurrentUser();
  const {agentId} = useParams();
  const [searchParams] = useSearchParams();
  const preview = searchParams.get('preview');

  const {
    data: agent,
    error: agentError,
    isLoading: isAgentLoading,
  } = useGetAgentByIdQuery(agentId || '0'); // should never happen

  const {
    data: availableServiceAreas,
    error: serviceAreasError,
    isLoading: areServiceAreasLoading,
  } = useGetAllServiceAreasQuery();

  const {
    data: availableLanguages,
    error: languagesError,
    isLoading: areLanguagesLoading,
  } = useGetAllLanguagesQuery();

  if (!agentId) return null;
  if (isAgentLoading) return <p>Loading details...</p>;
  if (!!agentError || !agent) return <AgentNotFound/>;

  if (!!preview) {
    return <>
      <PageTitle title={`${agent.first_name} ${agent.last_name}`}/>
      <CustomerAgentProfile agentId={agentId}/>
    </>;
  }

  if (['Admin', 'TryulaAdmin', 'Agent'].includes(user?.user_type || 'Customer')) {
    if (areServiceAreasLoading || areLanguagesLoading) {
      return <p>Loading available service areas and languages...</p>;
    }
    if (!!serviceAreasError || !availableServiceAreas) {
      return <RedError>Unknown error loading service areas</RedError>;
    }
    if (!!languagesError || !availableLanguages) {
      return <RedError>Unknown error loading languages</RedError>;
    }

    if (user?.user_type === 'Agent') {
      return user?.agent?.id.toString() === agentId
        ? <>
          <PageTitle title="Edit Profile"/>
          <UpdateAgentProfile
            agent={agent}
            availableServiceAreas={availableServiceAreas}
            availableLanguages={availableLanguages}/>
        </>
        : <>
          <PageTitle title={`${agent.first_name} ${agent.last_name}`}/>
          <CustomerAgentProfile agentId={agentId}/>
        </>;
    }

    return <>
      <PageTitle title="Edit Agent"/>
      <CreateOrEditAgent agent={agent}/>
    </>;
  }

  return <>
    <PageTitle title={`${agent.first_name} ${agent.last_name}`}/>
    <CustomerAgentProfile agentId={agentId}/>
  </>;
};
