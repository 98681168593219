import {SetSelectedValues} from "./BasicCheckboxSelection";

import {mapValues} from "lodash";
import {CheckboxState} from "../filter/types";

export const isChecked = (value: string, selectedValues: CheckboxState) => {
  if ('All' in selectedValues && selectedValues['All']) return true;

  return value in selectedValues && selectedValues[value];
};

interface ToggleHandlerOptions {
  selectedValues: CheckboxState;
  setSelectedValues: SetSelectedValues;
}

export const prepareToggleHandler =
  ({selectedValues, setSelectedValues}: ToggleHandlerOptions) =>
    (currentChecked: boolean, value: string): void => {
      const allTrue = mapValues(selectedValues, () => true);
      if (value === 'All' && !currentChecked) {
        return setSelectedValues(allTrue)
      }

      if (value === 'All' && currentChecked) {
        return setSelectedValues(mapValues(selectedValues, () => false));
      }

      const updatedValues: CheckboxState = {...selectedValues, 'All': false, [value]: !currentChecked};

      function allOtherValuesChecked() {
        for (const [k, v] of Object.entries(updatedValues)) {
          if (k === 'All') continue;

          if (!v) return false;
        }

        return true;
      }

      if (!currentChecked && allOtherValuesChecked()) {
        return setSelectedValues(allTrue);
      }

      return setSelectedValues(updatedValues);
    }