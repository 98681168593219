import {useTransaction} from "./TransactionContext";
import {nullSafeFullURL} from "../johncornish/utils/v1/urls";
import {BaseAPIUrl} from "../../services/constants";

export const Downloads = () => {
  const {canView, referralAgreementUrl, transactionState} = useTransaction();

  return <div className={'flex flex-col space-y-2'}>
    {canView('download-referral-agreement') && <a
      className="col-span-1 font-extrabold flex flex-row"
      data-testid="transaction-referral-agreement-link"
      href={nullSafeFullURL(referralAgreementUrl)}
    >
      Download Referral Agreement
    </a>}

    <a
      className="col-span-1 font-extrabold flex flex-row"
      data-testid="transaction-rebate-guarantee-link"
      href={`${BaseAPIUrl}docusign/rebate_guarantee_pdf/${transactionState.envelope_id}`}>
      Download Rebate Guarantee
    </a>
  </div>;
};