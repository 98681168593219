import {SetState} from "./Input";
import {ChangeEvent, ChangeEventHandler} from "react";

export const reloadAfterSuccess =
  () => window.location.reload();

export const textInputStateUpdater = (state: {}, setState: SetState):
  ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
  (
    {target: {name, value}}:
      ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) =>
    setState((prevState: typeof state) => ({
      ...prevState,
      [name]: value
    }));

export const fieldWithLabelReducer = (
  acc: { [k: string]: [string, {}?][] },
  currentValue: [string, string, {}?]
) => {
  const [label, ...fieldConfig] = currentValue;

  if (!(label in acc)) {
    acc[label] = []
  }

  acc[label] = [...acc[label], fieldConfig];

  return acc
};
export const sentenceCapitalization = (inputValue: string) => {
  const [firstLetter, ...text] = inputValue.split('');
  return firstLetter.toUpperCase() + text.join('');
};