import {useCurrentUser} from "../../features/auth/useUserData";
import {useEffect, useState} from "react";
import {PendingEngagementState} from "../object-control/pending-engagement";
import {useCreatePendingEngagementMutation, useGetAgentByIdQuery} from "../../services/tryulaApi";
import {AgentProfileProps} from "./AgentProfilePage";
import {createSubmitHandler} from "../johncornish/components/v1/form/createSubmitHandler";
import {isValidNumProperties} from "./customer-agent-profile-utils";
import {useSearchParams} from "react-router-dom";
import {ViewCustomerAgentProfile} from "./ViewCustomerAgentProfile";

export const CustomerAgentProfile = ({agentId}: AgentProfileProps) => {
  const [searchParams] = useSearchParams();
  const startEngaged: boolean = searchParams.get('engage') === 'true';

  const {user} = useCurrentUser();
  const [showEngageModal, setShowEngageModal] = useState(startEngaged);
  const [validNumProperties, setValidNumProperties] =
    useState(true);

  const [pendingEngagementError, setPendingEngagementError] = useState('');
  const [pendingEngagement, setPendingEngagement] =
    useState<PendingEngagementState>({
      customer_email: user?.email || '',
      agent_id: agentId,
      num_properties: '1'
    })
  const [isPendingEngagementSuccess, setIsPendingEngagementSuccess] = useState(false)
  const [createPendingEngagement, {isLoading: isPendingEngagementLoading}] = useCreatePendingEngagementMutation();

  const {
    refetch, // this is why I have to duplicate this query. Other agent pages are simpler.
    isUninitialized,
    data: agent,
    error: agentError,
    isLoading,
  } = useGetAgentByIdQuery(agentId);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isUninitialized) {
        refetch();
      }
    }, 2_000);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    setValidNumProperties(isValidNumProperties(pendingEngagement.num_properties));
  }, [pendingEngagement]);

  if (agentError) return <>
    Application error:
    {'status' in agentError && agentError.status}
    {'data' in agentError && JSON.stringify(agentError.data)}
  </>;

  if (isLoading) return <>Loading agent info...</>

  if (!agent) return <>Received empty response from {`agent/${agentId}`} API call</>;

  const unavailableAgentInformation = (agent.phone_number || '').includes('*');

  const handleSubmitEngagement = createSubmitHandler({
    mutator: createPendingEngagement,
    onSuccess: () => {
      setIsPendingEngagementSuccess(true);
    },
    setMutateError: setPendingEngagementError,
    state: pendingEngagement
  });

  return (
    <ViewCustomerAgentProfile
      agent={agent}
      user={user}
      onClickEngageAgent={() => setShowEngageModal(true)}
      unavailableAgentInformation={unavailableAgentInformation}
      showEngageModal={showEngageModal}
      setShowEngageModal={setShowEngageModal}
      validNumProperties={validNumProperties}
      handleSubmitEngagement={handleSubmitEngagement}
      isPendingEngagementLoading={isPendingEngagementLoading}
      pendingEngagementError={pendingEngagementError}
      pendingEngagement={pendingEngagement}
      setPendingEngagement={setPendingEngagement}
      isPendingEngagementSuccess={isPendingEngagementSuccess}
    />
  );
};