import {useCurrentUser} from "../features/auth/useUserData";
import {useEffect} from "react";
import {allow} from "../lib/auth/allow";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {AdminAgentsList} from "./AdminAgentsList";

export function AgentsPage() {
  const {user} = useCurrentUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Admin', 'TryulaAdmin'), []);

  return <>
    <PageTitle title="Agents"/>
    <AdminAgentsList userType={user?.user_type}/>
  </>;
}