// import axios from 'axios';
import {SetStateAction, useCallback, useState} from 'react';
import {AvatarEditor} from "./AvatarEditor";
import {RedError} from "../styled-components/RedError";
import {Agent} from "../object-control/agent";

export const CurrentUserPhoto = (
  {agent, setAvatar, viewer = 'agent'}:
    { agent: Agent, setAvatar?: ({files}: { files: any }) => void, viewer?: string }
) => {
  const [avatarURL, setAvatarURL] = useState(agent.avatar_url || '')
  const [avatarError, setAvatarError] = useState('');

  const onSaveUserPhoto = useCallback((newImageFile: { files: any; }, newAvatarURL: SetStateAction<string>) => {
    setAvatarError('');
    (async () => {
      setAvatarURL(newAvatarURL);
      !!setAvatar && setAvatar(newImageFile);
    })();
  }, []);

  return <>
    <AvatarEditor onSaveUserPhoto={onSaveUserPhoto} avatarURL={avatarURL} viewer={viewer}/>
    {!!avatarError && <RedError>{avatarError}</RedError>}
  </>;
};
