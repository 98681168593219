import {toTestId} from "../utils";
import {ContentHome, TryulaFullLogoSrc} from "../../config/siteSettings";
import {FC} from "react";

export type LinkComponentProps = { children: any, key: string | number, href: string };

interface LeftNavProps {
  links: [string, string][],
  listComponent?: FC<{ children: any }>,
  linkComponent?: FC<LinkComponentProps>
}


export const LeftNav = (
  {
    links,
    listComponent: ListComponent = basicUl,
    linkComponent: LinkComponent = basicLiA
  }: LeftNavProps
) =>
  <nav>
    <div className="logoLink flex items-center justify-between gap-2 px-6 py-6 lg:py-7">
      <ListComponent>
        <a href={ContentHome} data-testid="left-nav-logo-home" className="mb-5">
          <img src={TryulaFullLogoSrc} alt="Logo" width="190" height="47"/>
        </a>
        {links.map(([text, href], i) => <LinkComponent
          key={i}
          data-testid={`left-nav-${toTestId(...text.split(' '))}`}
          href={href}>
          {text}
        </LinkComponent>)}
      </ListComponent>
    </div>
  </nav>;


const basicUl = ({children}: any) =>
  <ul>{children}</ul>;

const basicLiA = ({children, key, ...props}: LinkComponentProps) =>
  <li key={key}>
    <a {...props}>{children}</a>
  </li>;

// export const LeftNav = ({links}: LeftNavProps) => <aside
//   className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0">
//   <div className="h-full px-3 py-4 overflow-y-auto">
//     <ul className="space-y-2">
//       {links.map(([text, href], i) => <li key={i}>
//         <a
//           data-testid={`left-nav-${toTestId(...text.split(' '))}`}
//           href={href}>{text}</a>
//       </li>)}
//     </ul>
//   </div>
// </aside>;