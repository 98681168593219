import {useDeleteCustomerMutation, useGetAllCustomersQuery} from "../services/tryulaApi";
import {useEffect, useState} from "react";
import {RedError} from "../lib/styled-components/RedError";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {DefaultPageLayout} from "../lib/layouts/DefaultPageLayout";
import {useCurrentUser} from "../features/auth/useUserData";
import {Customer} from "../lib/object-control/customer";
import {allow} from "../lib/auth/allow";
import {APIUserListPage} from "../lib/johncornish/components/v1/list/APIUserListPage";
import {DeleteWithConfirmation} from "../lib/styled-components/DeleteWithConfirmation";

export function CustomersPage() {
  const {
    data: customers,
    error,
    isLoading,
  } = useGetAllCustomersQuery();

  const {user} = useCurrentUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Admin', 'TryulaAdmin'), []);

  return <>
    <PageTitle title="Customers"/>
    <APIUserListPage
      items={customers}
      error={error}
      isLoading={isLoading}
      resourcePlural="customers"
      resourceSingular="customer"
      pageLayoutComponent={DefaultPageLayout}
      itemComponent={CustomerElement}
      emptyResultComponent={() => <p>No customers found.</p>}
    />
  </>;
}

const CustomerElement = ({customer}: { customer: Customer }) => {
  const [deleteCustomer] = useDeleteCustomerMutation();
  const [deleteError, setDeleteError] = useState('');

  const {user} = useCurrentUser();

  return <div data-testid="customer" className="grid grid-cols-3">
    <div className="col-span-1">
    <span data-testid="customer-name" className="break-words">
      {customer.email}
    </span>
    </div>

    {user?.user_type === 'Admin' && <div className="col-span-2 items-end">
      <DeleteWithConfirmation
        dataObject={customer}
        deleteMutator={deleteCustomer}
        setDeleteError={setDeleteError}
        uniqueIdentifier={customer.email}/>
    </div>}
    {!!deleteError && <RedError>{deleteError}</RedError>}
  </div>
};
