import {Radio} from "../styled-components/Radio";
import {TransactionColumn, TransactionRow} from "./TransactionCard";
import {FC, useCallback, useContext, useEffect, useState} from "react";
import {asTransactionState, TransactionData, TransactionState} from "../object-control/transactionData";
import {BlueLinkAnchor} from "../styled-components/BlueLinkAnchor";
import {BasicLoadingElement} from "../johncornish/components/v1/form/BasicLoadingElement";
import {Input} from "../johncornish/components/v1/form/Input";
import {GreenCheckmark} from "../styled-components/GreenCheckmark";
import {TransactionPageContext} from "../../Transactions/TransactionsPage";
import {CanEditFunc, CanViewFunc, getCanEditFields, getCanView, getViewer} from "./utils";
import {useCurrentUser} from "../../features/auth/useUserData";
import {TransactionActionsAndState, TransactionContext} from "./TransactionContext";
import {ErrorSection} from "./ErrorSection";
import {Downloads} from "./Downloads";
import {useUpdateTransactionMutation} from "../../services/tryulaApi";
import {FormOrStyledContainer} from "./FormOrStyledContainer";


type TransactionProps = {
  transaction: TransactionData,
};

export const Transaction: FC<TransactionProps> =
  ({transaction}) => {
    const {user} = useCurrentUser();
    const viewer = getViewer(user);

    const [
      transactionState,
      setTransactionState
    ] = useState<TransactionState>(asTransactionState(transaction));
    const [updateError, setUpdateError] = useState('');
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const {view, refetch} = useContext(TransactionPageContext);

    // TODO pull this out to Root
    const [updateTransaction, {isLoading: isUpdateLoading}] = useUpdateTransactionMutation();

    useEffect(() => {
      if (!!transactionState.transaction_status) {
        return
      }

      if (!!transactionState.transaction_type) {
        setTransactionState(prevState => ({...prevState, transaction_status: "active"}));
      }
    }, [setTransactionState, transactionState]);

    const radiosAreReadOnly =
      viewer !== 'agent'
      || transactionState.locked
      || view === 'archived';

    const namePropForColoredDisable = (prefix: string) =>
      radiosAreReadOnly ? {name: `${prefix}-${transaction.id}`} : {}

    const typeNotSet =
      !["buy", "sell"]
        .includes(transactionState.transaction_type ?? '');
    const statusNotSet =
      !["active", "pending", "closed"]
        .includes(transactionState.transaction_status ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const canEdit: CanEditFunc = useCallback(getCanEditFields({
      viewer,
      locked: transactionState.locked,
      transactionStatus: transactionState.transaction_status,
      view
    }), [viewer, transactionState.locked, transactionState.transaction_status, view]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const canView: CanViewFunc = useCallback(getCanView({
      viewer,
      archived: transactionState.archived,
      locked: transactionState.locked,
      transactionType: transactionState.transaction_type,
      transactionStatus: transactionState.transaction_status,
    }), [viewer, transactionState.archived, transactionState.locked, transactionState.transaction_type, transactionState.transaction_status]);

    // const Root: FC<{ initialTransactionData: TransactionData, children: ReactNode }> = ({initialTransactionData, children}) => {
    const contextValue: TransactionActionsAndState = {
      canEdit,
      canView,
      updateError,
      setUpdateError,
      updateSuccess,
      setUpdateSuccess,
      transactionState,
      setTransactionState,
      referralAgreementUrl: user?.agent?.referral_agreement_url ?? '',
      updateTransaction,
      refetch,
    };
    // return <TransactionContext.Provider value={contextValue}>
    //   {children}
    // </TransactionContext.Provider>;
    // }

    const showSaveAndArchive = canView('save') || canView('archive-or-unarchive');

    return <TransactionContext.Provider value={contextValue}>
      <FormOrStyledContainer viewer={viewer}>
        <div data-testid="transaction-card" className="transaction-card text-xs md:text-base bg-white pb-1 mb-1">
          <div className="grid grid-cols-3 justify-between p-3 items-center">
            <>
              {showSaveAndArchive && <div className="flex space-x-3">
                {canView('save')
                  && <input
                    data-testid="transaction-save"
                    type="submit"
                    value="Save"
                    className="bg-[#27AAE1] hover:bg-blue-500 flex justify-center rounded py-1 px-6 mr-3 font-normal text-gray hover:bg-opacity-90 text-white cursor-pointer"/>}

                {canView('archive-or-unarchive')
                  && <input
                    data-testid={`transaction-${transaction.archived ? 'unarchive' : 'archive'}`}
                    type="submit"
                    value={transaction.archived ? 'Unarchive' : 'Archive'}
                    className="flex justify-center rounded border border-bg-[#3c50e0] py-1 px-6 mr-3 font-normal text-gray hover:bg-[#3c50e0] hover:text-white cursor-pointer"/>}
                {isUpdateLoading && <BasicLoadingElement/>}
                {updateSuccess && <GreenCheckmark/>}
              </div>}

              {canView('loading-and-update-status')
                && <div className="flex space-x-3">
                  {isUpdateLoading && <BasicLoadingElement/>}
                  {updateSuccess && <GreenCheckmark/>}
                </div>}

              {canView('avatar') &&
                <div className="grid grid-cols-4">
                  <img
                    data-testid="avatar-url-receiver"
                    className="rounded-full col-span-1 aspect-square w-[56px] h-[56px] hidden md:block"
                    src={transaction.agent_avatar_url === null
                      ? undefined
                      : transaction.agent_avatar_url}
                    alt=""
                  />
                  <div className="col-span-3">
                    <p className="block overflow-x-clip">
                      {transaction.agent_name}
                    </p>
                    <BlueLinkAnchor
                      href={`/agent/${(transaction.agent_id)}`} data-testid="transaction-view-agent-profile-link">
                      View Profile
                    </BlueLinkAnchor>
                  </div>
                </div>}
            </>

            <div className={`space-x-3${typeNotSet ? ' font-extrabold' : ''}`}>
              <Radio
                readOnly={radiosAreReadOnly}
                {...namePropForColoredDisable('transaction_type-buy')}
                className="me-2"
                forValue={"buy"}
                forStateField={"transaction_type"}
                resourceName={"transaction"}
                state={transactionState}
                setState={setTransactionState}>
                Buy
              </Radio>

              <Radio
                readOnly={radiosAreReadOnly}
                {...namePropForColoredDisable('transaction_type-sell')}
                className="me-2"
                forValue={"sell"}
                forStateField={"transaction_type"}
                resourceName={"transaction"}
                state={transactionState}
                setState={setTransactionState}>
                Sell
              </Radio>
            </div>

            <Downloads/>
          </div>

          <ErrorSection/>

          <div className="grid grid-cols-3 px-5 justify-between space-x-2">
            <TransactionColumn key="active-fields">
              <div
                className={`grid grid-cols-1 gap-2 justify-items-center${statusNotSet ? ' font-extrabold' : ''}`}>
                <Radio
                  readOnly={radiosAreReadOnly}
                  {...namePropForColoredDisable('transaction_status-active')}
                  className="me-2 accent-green-400"
                  forValue={"active"}
                  forStateField={"transaction_status"}
                  resourceName={"transaction"}
                  state={transactionState}
                  setState={setTransactionState}>
                  Active
                </Radio>
              </div>

              <TransactionRow>
                <>Active Date</>
                <input
                  data-testid="transaction-active_since"
                  disabled={true}
                  className="border px-2 w-full disabled:bg-gray-200"
                  value={transactionState.active_since}/>
              </TransactionRow>

              {canView('customer_email') && <TransactionRow>
                <>Customer Email</>
                <input
                  disabled={true}
                  className="border px-2 w-full disabled:bg-gray-200"
                  value={transaction.customer_email || ""}/>
              </TransactionRow>}

              {canView('customer_name') && <TransactionRow>
                <>Customer Name</>
                <Input
                  resourceName="transaction"
                  disabled={!canEdit('active')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField={"customer_name"}
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>}
            </TransactionColumn>

            <TransactionColumn key="pending-fields">
              <div className={`grid ${
                viewer === 'agent'
                  ? 'grid-cols-2'
                  : 'grid-cols-1'
              } gap-2 justify-items-center`}>
                <Radio
                  readOnly={radiosAreReadOnly}
                  {...namePropForColoredDisable('transaction_status-pending')}
                  className="me-2 accent-yellow-400"
                  forValue={"pending"}
                  forStateField={"transaction_status"}
                  resourceName={"transaction"}
                  state={transactionState}
                  setState={setTransactionState}>
                  Pending
                </Radio>
                {canView('reset-to-active')
                  && <input
                    data-testid="transaction-reset-to-active"
                    type="submit"
                    value="Reset to Active"
                    disabled={!canEdit('pending')}
                    className="flex justify-center rounded bg-[#3c50e0] disabled:bg-gray-400 disabled:hover:cursor-not-allowed px-2 mr-3 font-normal text-gray hover:bg-opacity-90 text-white cursor-pointer"/>}

              </div>

              <TransactionRow>
                <>Pending Date</>
                <Input
                  resourceName="transaction"
                  disabled={!canEdit('pending')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField="pending_date"
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>

              <TransactionRow>
                <>MLS #</>
                <Input
                  resourceName="transaction"
                  disabled={!canEdit('pending')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField={"mls_number"}
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>

              <TransactionRow>
                <span data-testid="pending-price-label">Pending Price</span>
                <Input
                  price={true}
                  resourceName="transaction"
                  disabled={!canEdit('pending')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField={"pending_price"}
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>
            </TransactionColumn>

            <TransactionColumn>
              <div className="grid grid-cols-1 gap-2 justify-items-center">
                <Radio
                  readOnly={radiosAreReadOnly}
                  {...namePropForColoredDisable('transaction_status-closed')}
                  className="me-2 accent-red-400"
                  forValue={"closed"}
                  forStateField={"transaction_status"}
                  resourceName={"transaction"}
                  state={transactionState}
                  setState={setTransactionState}>
                  Closed
                </Radio>
              </div>

              <TransactionRow>
                <>Closing Date</>
                <Input
                  resourceName="transaction"
                  disabled={!canEdit('closed')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField="closing_date"
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>

              <TransactionRow>
                <>MLS #</>
                <Input
                  resourceName="transaction"
                  disabled={true}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField={"mls_number"}
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>

              <TransactionRow>
                <span data-testid="closing-price-label">Closing Price</span>
                <Input
                  price={true}
                  resourceName="transaction"
                  disabled={!canEdit('closed')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField={"closing_price"}
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>

              {canView('compensation_rate') && <TransactionRow>
                <span data-testid="compensation-rate-label">Compensation Rate</span>
                <Input
                  percent={true}
                  resourceName="transaction"
                  disabled={!canEdit('closed')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField={"compensation_rate"}
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>}
            </TransactionColumn>
          </div>

          <div className="grid grid-cols-3 px-5 justify-between space-x-2">
            {canView('address') && <div className="col-span-2">
              <TransactionRow>
                <>Address</>
                <Input
                  resourceName="transaction"
                  disabled={!canEdit('address')}
                  className="border px-2 w-full disabled:bg-gray-200"
                  forStateField={"address"}
                  state={transactionState}
                  setState={setTransactionState}/>
              </TransactionRow>
            </div>}
          </div>

          {canView('private_notes') && <div className="px-5">
            <label className="font-semibold text-[#27aae1]"
                   htmlFor={`transaction-private-notes-${transactionState.id}`}>
              Private Notes
            </label>
            <Input
              id={`transaction-private-notes-${transactionState.id}`}
              resourceName="transaction"
              textarea={true}
              disabled={!canEdit('private_notes')}
              className="border px-2 resize-none w-full disabled:bg-gray-200"
              rows={3}
              forStateField={"private_notes"}
              state={transactionState}
              setState={setTransactionState}/>
          </div>}
        </div>
      </FormOrStyledContainer>
    </TransactionContext.Provider>;
  }