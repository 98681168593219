import {useCurrentUser} from "../features/auth/useUserData";
import {useEffect} from "react";
import {allow} from "../lib/auth/allow";
import {CustomerDashboard} from "./CustomerDashboard";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {AdminStats} from "./AdminStats";

export function CustomerOrAdminDashboardPage() {
  const {user} = useCurrentUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Admin', 'TryulaAdmin', 'Agent', 'Customer'), []);

  if (user?.user_type === 'Agent') {
    window.location.replace('/transactions');
    return null;
  }

  if (user?.user_type === 'Admin' || user?.user_type === 'TryulaAdmin') {
    return <>
      <PageTitle title="Dashboard"/>
      <AdminStats/>
    </>;
  }

  return <>
    <PageTitle title="Dashboard"/>
    <CustomerDashboard/>
  </>;
}

