import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useGetKeepAliveQuery} from "../../services/tryulaApi";
import {useEffect} from "react";
import {toast} from "react-toastify";
import {setCredentials} from "../../features/auth/userDataSlice";
import {pageIsBounceExemptForUser} from "./bouncer-utils";
import {useCurrentUser} from "../../features/auth/useUserData";
import {loginHref} from "../utils";
import {BasicLoadingElement} from "../johncornish/components/v1/form/BasicLoadingElement";
import {RootState} from "../../store";

interface BouncerProps {
  children: any;
  timeout?: number | undefined;
}

export function Bouncer({children, timeout = 15_000}: BouncerProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {user} = useCurrentUser();

  const isRehydrated = useSelector((state: RootState) => state._persist?.rehydrated);

  const {
    isUninitialized,
    refetch,
    data,
    error
  } = useGetKeepAliveQuery({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!isUninitialized) {
        refetch();
      }
    }, timeout);

    return () => {
      clearInterval(intervalId);
    }
  }, []);

  useEffect(() => {
    if (!!error && 'status' in error && error?.status === 401) {
      if (pageIsBounceExemptForUser(user)) {
        return;
      }

      toast.info('Login expired');

      setTimeout(() => {
        dispatch(setCredentials({user: null, token: null}));

        navigate(loginHref());
      }, 1_000);
    }
  }, [data, error]);

  if (!isRehydrated) {
    return <BasicLoadingElement />;
  }

  return children;
}