import {RadioProps} from "./Radio";
import {FC} from "react";
import {FieldConfig} from "./types";
import {Input} from "./Input";
import {sentenceCapitalization} from "./utils";

export const prepareConfigToFieldMapper = (
  resourceName: string,
  state: {},
  setState: (value: any) => void,
  RadioElement: FC<RadioProps>,
  disabled: boolean = false
) => ([forStateField, options = {}]: FieldConfig) => {
  if (options?.radio) {
    const [forValue, textOverride] = options.radio;

    return <RadioElement
      key={`${forStateField}-${forValue}`}
      forValue={forValue}
      forStateField={forStateField}
      resourceName={resourceName}
      state={state}
      setState={setState}
      disabled={disabled}
      className={options?.radioClassName || ''}
    >{textOverride || sentenceCapitalization(forValue)}</RadioElement>;
  }

  if (options?.readOnly) {
    return <input
      key={forStateField}
      name={forStateField}
      data-testid={`${resourceName}-${forStateField}`}
      value={(state as any)[forStateField] || ''}
      readOnly={true}
      disabled={true}
      className={options?.className || ''}/>;
  }

  // options?.tel country code, formatting, etc.
  // const phoneProps = options?.tel ? {
  //   type: 'text',
  //   pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}"
  // } : {};

  if (options?.file) {
    return <input
      key={forStateField}
      name={forStateField}
      type="file"
      data-testid={`${resourceName}-${forStateField}`}
      onChange={(
        {target: {name, files}}:
          React.ChangeEvent<HTMLInputElement>
      ) =>
        setState((prevState: typeof state) => ({
          ...prevState,
          [name]: files ? files[0] : {}
        }))}
      className={options?.className || ''}
    />;
  }

  return <Input
    key={forStateField}
    forStateField={forStateField}
    resourceName={resourceName}
    state={state}
    setState={setState}
    textarea={!!options?.textarea}
    disabled={disabled}
    {...options}
  />;
};