import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {tryulaApi} from "./services/tryulaApi";
import {setupListeners} from "@reduxjs/toolkit/query";
import userDataReducer from './features/auth/userDataSlice';
import storage from "redux-persist/lib/storage";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import {authApi} from "./services/authApi";

const reducers = {
  [tryulaApi.reducerPath]: tryulaApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  userData: userDataReducer,
};

export const combinedReducer = combineReducers(reducers);

const persistConfig = {
  key: 'root',
  whitelist: ['userData'],
  version: 1,
  storage,
}

const rootReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      }
    })
      .concat(
        authApi.middleware,
        tryulaApi.middleware
      )
})

export const persistor = persistStore(store, null, () => {
  console.log('Rehydration complete');
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
