import {FilterFieldConfig} from "../johncornish/components/v1/filter/types";
import {allValuesSelected} from "../johncornish/components/v1/filter/utils";
import {FieldWithLabelConfig} from "../johncornish/components/v1/form/types";

export type TransactionType = 'buy' | 'sell' | null;
export type TransactionStatus = 'active' | 'pending' | 'closed' | null;

export interface TransactionData {
  id: number;
  agent_id: number;
  customer_id: number,
  envelope_id: string,
  agent_name: string | null;
  agent_avatar_url: string | null;
  customer_name: string | null,
  customer_email: string | null,
  transaction_type: TransactionType,
  transaction_status: TransactionStatus,
  active_since: string | null,
  pending_date: string | null,
  closing_date: string | null,
  address: string | null,
  pending_price: number | null,
  closing_price: number | null,
  compensation_rate: number | null,
  private_notes: string | null,
  mls_number: number | null,
  archived: boolean | null,
  locked: boolean | null,
  created_at: string;
  updated_at: string;
}

export interface TransactionState {
  id?: number;
  agent_id?: number;
  customer_id?: number,
  envelope_id?: string,
  agent_name?: string;
  customer_name?: string,
  customer_email?: string,
  transaction_type?: 'buy' | 'sell',
  transaction_status?: 'active' | 'pending' | 'closed',
  active_since?: string,
  pending_date?: string | null,
  closing_date?: string,
  address?: string | null,
  pending_price?: number | null,
  closing_price?: number,
  compensation_rate?: number | null,
  private_notes?: string,
  mls_number?: number | null,
  archived?: boolean
  locked?: boolean
}

export interface ManualTransactionState {
  agent_powerform_id: string;
  customer_email: string,
  envelope_id: string,
  num_transactions: number
}

export const asTransactionState = (transaction: TransactionData): TransactionState =>
  ({
    id: transaction.id,
    agent_id: transaction.agent_id,
    customer_id: transaction.customer_id,
    envelope_id: transaction.envelope_id,
    agent_name: transaction.agent_name || undefined,
    customer_name: transaction.customer_name || undefined,
    customer_email: transaction.customer_email || undefined,
    transaction_type: transaction.transaction_type || undefined,
    transaction_status: transaction.transaction_status || undefined,
    active_since: transaction.active_since || undefined,
    pending_date: transaction.pending_date || undefined,
    closing_date: transaction.closing_date || undefined,
    address: transaction.address || undefined,
    pending_price: transaction.pending_price || undefined,
    closing_price: transaction.closing_price || undefined,
    compensation_rate: transaction.compensation_rate || undefined,
    private_notes: transaction.private_notes || undefined,
    mls_number: transaction.mls_number || undefined,
    locked: typeof transaction.locked === 'boolean' ? transaction.locked : undefined,
  });

export const CustomerViewableTransactionFields: FieldWithLabelConfig[] = [
  ["Active Since", "active_since", {readOnly: true, className: 'p-1 w-full border p-2'}],
  ["Pending Since", "pending_date", {readOnly: true, className: 'p-1 border w-full p-2'}],
  ["Closing Date", "closing_date", {readOnly: true, className: 'p-1 border w-full p-2'}],
  ["Pending Price", "pending_price", {className: 'p-1 border w-full p-2'}],
  ["Closing Price", "closing_price", {className: 'p-1 border w-full p-2'}],
  ["Address", "address", {className: 'p-1 border w-full p-2'}],
  ["MLS #", "mls_number", {className: 'p-1 border w-full p-2'}]
];
export const AdminViewableTransactionFields: FieldWithLabelConfig[] = [
  ["Customer Name", "customer_name", {className: 'p-1 border w-full p-2'}],
  ...CustomerViewableTransactionFields,
];
export const AgentEditableTransactionFields: FieldWithLabelConfig[] = [
  ...AdminViewableTransactionFields,
  ["Private Notes", "private_notes", {textarea: true, className: 'w-full min-h-36 border p-2'}]
];

export const ShowTransactionFields = {
  'Active Since': (state: TransactionState) =>
    'active' === state.transaction_status,
  'Pending Since': (state: TransactionState) =>
    'pending' === state.transaction_status,
  'Closing Date': (state: TransactionState) =>
    'closed' === state.transaction_status,
  'Pending Price': (state: TransactionState) =>
    'pending' === state.transaction_status,
  'Closing Price': (state: TransactionState) =>
    'closed' === state.transaction_status,
  'Address': (state: TransactionState) =>
    !('active' === state.transaction_status && 'buy' === state.transaction_type)
};
export type AnyTransactionComponentProps = { transaction: TransactionData };

export const AvailableTypes = ['buy', 'sell'];
export const AvailableStatuses = ['active', 'pending', 'closed'];
export const CustomerTransactionFilterFields: FilterFieldConfig[] = [
  ['Type', 'transaction_type', {checkboxSelection: allValuesSelected(AvailableTypes), inlineOpen: true}],
  ['Status', 'transaction_status', {checkboxSelection: allValuesSelected(AvailableStatuses), inlineOpen: true}],
];
