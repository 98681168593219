import {User} from "../object-control/user";

export const pageIsBounceExemptForUser =
  (user: User | null) => {
    if (window.location.pathname.includes('/agent/')) {
      return user === null;
    }

    return [
      '/',
      '/login',
      '/sign-up'
    ].includes(window.location.pathname);
  };