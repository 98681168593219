import {FC, ReactNode} from "react";
import {useTransaction} from "./TransactionContext";
import {RedError} from "../styled-components/RedError";
import {createFormSubmitHandler} from "../johncornish/components/v1/form/createFormSubmitHandler";
import {beforeTransactionMutateProcessSpecialButtons, mergeWhileProtectingUndefined} from "./utils";
import {reloadAfterSuccess} from "../johncornish/components/v1/form/utils";
import {validators} from "./validation";

export const FormOrStyledContainer: FC<{ viewer: string, children: ReactNode; }> =
  ({viewer, children}) => {
    const {
      updateTransaction,
      setUpdateError,
      transactionState,
      setUpdateSuccess,
      setTransactionState,
      refetch,
    } = useTransaction();

    // TODO merge with Root
    if (!['admin', 'agent'].includes(viewer)) {
      return <section className="bg-white pb-2">
        {children}
      </section>
    }

    if (!updateTransaction) {
      return <RedError>
        Something went wrong in enabling editing for this transaction.
        Contact support@tryula.com
      </RedError>
    }

    const handleTransactionUpdate = createFormSubmitHandler({
      mutator: updateTransaction,
      setMutateError: setUpdateError,
      state: transactionState,
      processStateWithSubmitterValue: beforeTransactionMutateProcessSpecialButtons,
      onSuccess: (result, submitterValue) => {
        setUpdateSuccess(true);
        if (submitterValue === 'Archive' || submitterValue === 'Unarchive') {
          reloadAfterSuccess();
        } else {
          setTransactionState(mergeWhileProtectingUndefined(result))
          setTimeout(() => setUpdateSuccess(false), 750);
          refetch();
        }
      },
      validate: validators
    });

    return <form onSubmit={handleTransactionUpdate} className="bg-white pb-2">
      {children}
    </form>
  };