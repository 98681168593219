import {JSX} from "react";
import {FragmentWrapper} from "./FragmentWrapper";

interface SplitScreenProps {
  children: [JSX.Element, JSX.Element];
  screenComponent: ({children}: any) => JSX.Element;
  leftComponent?: ({children}: any) => JSX.Element;
  rightComponent?: ({children}: any) => JSX.Element;
}

export function SplitScreen(
  {
    children,
    screenComponent: ScreenComponent,
    leftComponent: LeftComponent = FragmentWrapper,
    rightComponent: RightComponent = FragmentWrapper
  }: SplitScreenProps
) {
  const [leftChild, rightChild] = children;
  return <ScreenComponent>
    <LeftComponent>{leftChild}</LeftComponent>
    <RightComponent>{rightChild}</RightComponent>
  </ScreenComponent>;
}