import {
  Agent,
  AgentEditProfileFields,
  UpdateAgentProfileState,
  ValidateUpdateAgentProfileFields
} from "../object-control/agent";
import {useState} from "react";
import {SplitScreen} from "../johncornish/components/v1/screen/SplitScreen";
import {useUpdateAgentMutation} from "../../services/tryulaApi";
import {RedError} from "../styled-components/RedError";
import {GreenCheckmark} from "../styled-components/GreenCheckmark";
import {CurrentUserPhoto} from "./CurrentUserPhoto";
import {withAvailableAndChosen} from "../johncornish/components/v1/filter/utils";
import {BlueLinkAnchor} from "../styled-components/BlueLinkAnchor";
import {ContentHome} from "../../config/siteSettings";
import {Language, ServiceArea} from "../object-control/language-or-service-area";
import {ProfileFieldRow} from "../styled-components/ProfileFieldRow";
import {BasicObjectUpdatingForm} from "../johncornish/components/v1/form/BasicObjectUpdatingForm";
import {getFilterCheckboxes} from "../johncornish/components/v1/checkbox-selection/getFilterCheckboxes";
import {CheckboxState} from "../johncornish/components/v1/filter/types";
import {TailwindFullWidthRows} from "../styled-components/TailwindFullWidthRows";
import {Modal} from "../styled-components/Modal";

type UpdateAgentProfileProps = {
  agent: Agent,
  availableServiceAreas: ServiceArea[],
  availableLanguages: Language[]
};

export function UpdateAgentProfile(
  {agent, availableServiceAreas, availableLanguages}: UpdateAgentProfileProps
) {
  const [agentState, setAgentState] = useState<UpdateAgentProfileState>({...agent, privacy_policy_consent: true});
  const setAvatar = ({files}: { files: File[] }) =>
    setAgentState(prevState =>
      ({...prevState, avatar: files ? files[0] : undefined}));

  const [updateSuccess, setUpdateSuccess] = useState(false);

  const afterSuccess = () => {
    setUpdateSuccess(true);
  };

  return <SplitScreen screenComponent={TailwindFullWidthRows}>
    <CurrentUserPhoto
      agent={agent}
      setAvatar={setAvatar}
    />

    <div className="col-span-1">
      <Modal show={updateSuccess} setShow={setUpdateSuccess}>
        <h3 className="text-2xl font-semibold">Thank you!</h3>

        <p>
          Your profile edits will be approved within 72 hours.
        </p>

        <a href={ContentHome}
           className="bg-[#A9D046] text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:hover:cursor-not-allowed disabled:bg-gray-400">
          Return Home
        </a>
      </Modal>

      <BasicObjectUpdatingForm
        resourceName="agent"
        state={agentState}
        setState={setAgentState}
        fieldsWithLabels={AgentEditProfileFields}
        useMutator={useUpdateAgentMutation}
        validate={ValidateUpdateAgentProfileFields}
        errorElement={RedError}
        afterSuccess={afterSuccess}
        updateSuccessElement={GreenCheckmark}
        saveClassName="ml-1 bg-[#27AAE1] hover:bg-blue-500 hover:cursor-pointer text-white font-bold px-4 py-1 rounded"
        rowElement={ProfileFieldRow}
      >
        <>
          <ProfileFieldRow>
            <>Service Areas</>
            <ListSelection selectionName="service_areas"
                           selectedValues={
                             withAvailableAndChosen(
                               availableServiceAreas, agentState.service_areas)}
                           updateAgentChosenValues={
                             (service_areas: string[]) =>
                               setAgentState(prevState =>
                                 ({...prevState, service_areas}))}/>
          </ProfileFieldRow>

          <ProfileFieldRow>
            <>Languages</>
            <ListSelection selectionName="languages"
                           selectedValues={
                             withAvailableAndChosen(
                               availableLanguages, agentState.languages)}
                           updateAgentChosenValues={
                             (languages: string[]) =>
                               setAgentState(prevState => {
                                 return ({...prevState, languages});
                               })}/>
          </ProfileFieldRow>
        </>
      </BasicObjectUpdatingForm>

      <div className="pt-6 pb-10">
        <BlueLinkAnchor
          href="/opt-out"
          data-testid="agent-opt-out">
          Opt out of Tryula.com
        </BlueLinkAnchor>
      </div>
    </div>
  </SplitScreen>;
}

interface ListSelectionProps {
  updateAgentChosenValues: (values: string[]) => void,
  selectionName: 'service_areas' | 'languages',
  selectedValues: { [k: string]: boolean }
}

const ListSelection = (
  {selectionName, selectedValues, updateAgentChosenValues}: ListSelectionProps,
) => {
  return <div data-testid={`${selectionName}-selection`}>
    <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 mt-3 mb-5 gap-2 gap-x-16">
      {getFilterCheckboxes(
        selectionName,
        selectedValues,
        checkboxValues => {
          const {All, ...validValues} = checkboxValues;
          const values = asStringArray(validValues);
          updateAgentChosenValues(values);
        }
      )}
    </div>
  </div>
};

function asStringArray(validServiceAreas: Omit<CheckboxState, "All">) {
  return Object
    .entries(validServiceAreas)
    .filter(([, selected]: [string, boolean]) => selected)
    .map(([key]) => key);
}
