import {
  asTransactionState,
  TransactionData,
  TransactionState,
  TransactionStatus,
  TransactionType
} from "../object-control/transactionData";
import {TransactionPageView} from "../../Transactions/TransactionsPage";
import {User} from "../object-control/user";

export const beforeTransactionMutateProcessSpecialButtons = (state: TransactionState, submitterValue?: string): TransactionState => {
  if (submitterValue === 'Archive') {
    return {...state, archived: true};
  }

  if (submitterValue === 'Unarchive') {
    return {...state, archived: false};
  }

  if (submitterValue === 'Unlock') {
    return {...state, locked: false};
  }

  if (submitterValue === 'Reset to Active') {
    return {
      ...state,
      transaction_status: "active",
      pending_date: null,
      address: state.transaction_type === 'sell' ? state.address : null,
      pending_price: null,
      mls_number: null
    };
  }

  return state;
};

export const mergeWhileProtectingUndefined =
  (result: TransactionData) =>
    (prevState: Partial<TransactionState>) => ({
      ...prevState,
      ...asTransactionState(result)
    });

type GetCanViewProps = {
  viewer: "admin" | "agent" | "customer",
  archived?: boolean | null,
  locked?: boolean,
  transactionType?: TransactionType,
  transactionStatus?: TransactionStatus,
};
type registeredFieldOrAction =
  | 'customer_email'
  | 'customer_name'
  | 'private_notes'
  | 'compensation_rate'
  | 'address'
  | 'avatar'
  | 'reset-to-active'
  | 'archive-or-unarchive'
  | 'loading-and-update-status'
  | 'download-referral-agreement'
  | 'save'
  ;
export type CanViewFunc = (fieldOr: registeredFieldOrAction) => boolean;
export const getCanView: (props: GetCanViewProps) => CanViewFunc =
  ({viewer, archived, locked, transactionType, transactionStatus}: GetCanViewProps): CanViewFunc =>
    (fieldOrAction: registeredFieldOrAction): boolean => {
      switch (fieldOrAction) {
        case 'save':
          return !archived && !locked && viewer === "agent";

        case 'avatar':
          return ['admin', 'customer'].includes(viewer);

        case 'customer_name':
        case 'customer_email':
          return !['customer', undefined].includes(viewer);

        case 'address':
          if (transactionType === 'buy') {
            return ['pending', 'closed'].includes(transactionStatus ?? '');
          }

          return true;

        case 'compensation_rate':
          return ['admin', 'agent'].includes(viewer);

        case 'private_notes':
        case 'reset-to-active':
        case 'archive-or-unarchive':
        case 'download-referral-agreement':
          return viewer === 'agent';

        case 'loading-and-update-status':
          return viewer === 'admin';
      }

      return false;
    };


// export type CanEditFunc = (status: TransactionStatus) => boolean
type GetCanEditFieldsProps = {
  viewer: "agent" | "admin" | "customer",
  locked: undefined | boolean,
  transactionStatus: "active" | "pending" | "closed" | undefined,
  view: TransactionPageView | undefined
};
type registeredStatusOrSection =
  TransactionStatus
  | 'address'
  | 'private_notes'
  ;
export type CanEditFunc = (fieldOr: registeredStatusOrSection) => boolean;
export const getCanEditFields =
  ({viewer, locked, transactionStatus, view}: GetCanEditFieldsProps) =>
    (statusOrSection: registeredStatusOrSection) => {
      if (viewer !== 'agent') return false;
      if (locked) return false;
      if (view === 'archived') return false;
      if (view === 'closed') return false;

      switch (statusOrSection) {
        case 'address':
          return ['active', 'pending'].includes(transactionStatus ?? '');
        case 'private_notes':
          return !locked
            && ["active", "pending", "closed"].includes(transactionStatus ?? '');
      }

      return transactionStatus === statusOrSection;
    }

type Viewer = 'agent' | 'admin' | 'customer';
export const getViewer = (user: User | null): Viewer => {
  if (user === null) {
    // TODO they wouldn't get this far but I don't see clearly how else to deal with this yet
    return 'customer';
  }

  switch (user?.user_type) {
    case 'Customer':
      return 'customer';
    case 'Agent':
      return 'agent';
    case 'Admin':
    case 'TryulaAdmin':
      return 'admin';
  }
}