import {HasNumericId, List} from "./List";
import {FilterState} from "../filter/types";

type APIUserListPageProps = {
  items: HasNumericId[] | undefined,
  error: any,
  isLoading: boolean,
  resourcePlural: string,
  resourceSingular: string,
  pageLayoutComponent: any,
  itemComponent: any,
  emptyResultComponent: any,
  filter?: FilterState
};

export function APIUserListPage(
  {
    items,
    error,
    isLoading,
    resourceSingular,
    resourcePlural,
    pageLayoutComponent: PageLayoutComponent,
    itemComponent,
    emptyResultComponent: EmptyResultComponent,
    filter
  }: APIUserListPageProps
) {
  if (isLoading) return null;

  if (error) return <h2>
    Failed to load {resourcePlural}.
    You may need to login again.
  </h2>

  if (!items || items.length === 0) return <EmptyResultComponent/>;

  return <PageLayoutComponent>
    <List filter={filter}
          items={items}
          resourceName={resourceSingular}
          itemComponent={itemComponent}/>
  </PageLayoutComponent>;
}