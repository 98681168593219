import {asUpdateError} from "./asUpdateError";
import {FormSubmitHandlerOptions} from "./types";
import {reloadAfterSuccess} from "./utils";

export const createSubmitHandler = (
  {
    mutator,
    onError,
    onSuccess = reloadAfterSuccess,
    setMutateError,
    state,
    wrapState = state => state,
    processStateWithSubmitterValue = state => state,
    validate = [],
    beforeMutate = () => {}
  }: FormSubmitHandlerOptions) => async (submitterValue?: string | any) => {
  setMutateError('');

  const processedState = processStateWithSubmitterValue(
    state, submitterValue
  );

  for (const [stateField, validator, message] of validate) {
    if (stateField !== '' && !(stateField in processedState)) {
      setMutateError(message);
      return;
    }

    if (!validator(processedState, submitterValue)) {
      setMutateError(message);
      return;
    }
  }

  beforeMutate();

  try {
    const mutateResult = await mutator(
      wrapState(processedState)).unwrap();
    onSuccess(mutateResult, submitterValue);
  } catch (err: any) {
    if (!onError) {
      setMutateError(asUpdateError(err))
      return;
    }

    onError(err);
  }
};