import {Modal} from "../styled-components/Modal";
import {Input} from "../johncornish/components/v1/form/Input";
import {PostEngagementDirections} from "../../config/siteSettings";
import {RedError} from "../styled-components/RedError";
import {getAgentURLWithCustomerEmail} from "./customer-agent-profile-utils";
import {BasicLoadingElement} from "../johncornish/components/v1/form/BasicLoadingElement";
import {PendingEngagement} from "../object-control/pending-engagement";
import {User} from "../object-control/user";
import {FC} from "react";

export type EngageModalProps = {
  show: boolean,
  setShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  pendingEngagement: PendingEngagement,
  isPendingEngagementSuccess: boolean,
  setPendingEngagement: (value: (((prevState: PendingEngagement) => PendingEngagement) | PendingEngagement)) => void,
  user: User | null,
  pendingEngagementError: string | undefined,
  validNumProperties: boolean,
  handleSubmitEngagement: (submitterValue?: any) => Promise<void>,
  agent: any,
  isPendingEngagementLoading: any
};

export const EngageModal: FC<EngageModalProps> = (props) => {
  return <Modal show={props.show} setShow={props.setShow}>
    <h3 className="text-xl font-semibold">Are you buying and/or selling more than 1 property?</h3>

    <div className="flex flex-col gap-1 max-w-lg">
      <div className="space-x-2 align-middle text-center pb-3">
        <Input className="p-2 border w-16"
               autoFocus={true}
               forStateField={"num_properties"}
               resourceName={"pending-engagement"}
               type="number"
               state={props.pendingEngagement}
               disabled={props.isPendingEngagementSuccess}
               setState={props.setPendingEngagement}/>
        <span>
              {props.pendingEngagement.num_properties === "1" ? "property" : "properties"}.
            </span>
      </div>
      <strong className={'on-docusign-instructions'}>
        On DocuSign, the first signer must use the email from this Tryula.com
        account{!!props.user ? ` (${props.user?.email})` : ""}.
        First signer must add the name and email of anyone else appearing on title so they can also sign the Rebate
        Guarantee.
        Please close the Docusign window after signing to return to this page.
      </strong>
      {props.isPendingEngagementSuccess
        && <p className={'mt-2'}>
          {PostEngagementDirections}
        </p>}
      {!!props.pendingEngagementError && <RedError>{props.pendingEngagementError}</RedError>}
      {!props.validNumProperties && <RedError>Invalid number of properties</RedError>}
    </div>

    {props.isPendingEngagementSuccess
      || <button
        data-testid="submit-engagement"
        onClick={props.handleSubmitEngagement}
        disabled={!props.validNumProperties}
        className="bg-[#A9D046] text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:hover:cursor-not-allowed disabled:bg-gray-400"
        type="button">
        {props.validNumProperties
          ? <a href={getAgentURLWithCustomerEmail(props.agent.powerform_url, props.user?.email ?? null)}
               target="_blank"
               rel="noreferrer">Engage</a>
          : "Engage"}
        {props.isPendingEngagementLoading && <BasicLoadingElement/>}
      </button>}

    {props.isPendingEngagementSuccess ? <>Refresh the agent profile page to restart the engagement process.</> :
      <span className="text-base">{props.user?.email ?? null}</span>}
  </Modal>;
}