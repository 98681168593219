import {useDispatch} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {authApi, useLogoutMutation} from "../services/authApi";
import {useEffect} from "react";
import {setCredentials} from "../features/auth/userDataSlice";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {PURGE} from "redux-persist";
import {tryulaApi} from "../services/tryulaApi";

export function LogoutPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logout] = useLogoutMutation();

  const [searchParams] = useSearchParams();
  const overrideUrl = searchParams.get('override_url');

  useEffect(() => {
    async function handleLogout() {
      try {
        await logout().unwrap()
      } catch (err: any) {
        console.error('Error while logging out:')
        console.error(err)
      }

      dispatch({ /* purge persisted session */
        type: PURGE,
        key: 'root',
        result: () => null
      })
      dispatch(setCredentials({user: null, token: null}));
      dispatch(authApi.util.resetApiState());
      dispatch(tryulaApi.util.resetApiState());

      if (!overrideUrl) {
        navigate('/');
        return;
      }

      window.location.replace(overrideUrl);
    }

    handleLogout();
  }, []);

  return <PageTitle title="Logout"/>;
}