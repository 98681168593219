import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store";

import {User} from "../../lib/object-control/user";


export interface AuthState {
  user: User | null;
  token: string | null;
}

const slice = createSlice({
  name: 'userData',
  initialState: {user: null, token: null} as AuthState,
  reducers: {
    setCredentials: (
      state,
        {
          payload: {user, token},
        }: PayloadAction<{ user: User | null; token: string | null }>,
    ) => {
      state.user = user
      state.token = token
    }
  }
})

export const {setCredentials} = slice.actions;
export default slice.reducer;
export const selectCurrentUser = (state: RootState) => state.userData.user;
export const selectAuthToken = (state: RootState) => state.userData.token;
