import {JSX, useState} from "react";
import {SetState} from "./Input";
import {UseMutation} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {Radio, RadioProps} from "./Radio";
import {FieldWithLabelConfig, KeyValueRowElement, ShowFields, StateValidators} from "./types";
import {reloadAfterSuccess} from "./utils";
import {BasicLoadingElement} from "./BasicLoadingElement";

import {BasicFormRow} from "./BasicFormRow";
import {createFormSubmitHandler} from "./createFormSubmitHandler";
import {labelsToFields} from "./LabelsToFields";

type FormProps = {
  resourceName: string,
  state: {},
  setState: SetState,
  fieldsWithLabels: FieldWithLabelConfig[],
  showFields?: ShowFields,
  useMutator: UseMutation<any>,
  saveText?: string,
  rowElement?: KeyValueRowElement,
  radioElement?: ({children, ...props}: RadioProps) => JSX.Element,
  loadingElement?: () => JSX.Element,
  errorElement: ({children}: { children: any }) => JSX.Element,
  updateSuccessElement: () => JSX.Element,
  afterSuccess?: () => void,
  validate?: StateValidators,
  children?: any,
  saveClassName?: string
};

export const BasicObjectUpdatingForm = (
  {
    resourceName,
    state,
    setState,
    fieldsWithLabels,
    showFields,
    useMutator,
    saveText = 'Save',
    rowElement: RowElement = BasicFormRow,
    radioElement: RadioElement = Radio,
    loadingElement: LoadingElement = BasicLoadingElement,
    errorElement: ErrorElement,
    updateSuccessElement: UpdateSuccessElement,
    afterSuccess = reloadAfterSuccess,
    validate,
    children: additionalFields = null,
    saveClassName = ''
  }: FormProps) => {
  const [
    updateError,
    setUpdateError
  ] = useState<string>()

  const [
    showUpdateSuccess,
    setShowUpdateSuccess
  ] = useState<boolean>(false)

  const [
    updateApiData,
    {isLoading}
  ] = useMutator();

  const fields = labelsToFields({
    fieldsWithLabels,
    resourceName,
    state,
    setState,
    RadioElement,
    showFields,
    RowElement
  });

  const handleSubmit = createFormSubmitHandler({
    setMutateError: setUpdateError,
    mutator: updateApiData,
    state: state,
    onSuccess: result => {
      setState(result)
      setShowUpdateSuccess(true);
      setTimeout(() => {
        setShowUpdateSuccess(false);
        !!afterSuccess && afterSuccess();
      }, 500)
    },
    validate: validate
  });

  return <form onSubmit={handleSubmit}>
    {fields}
    {additionalFields}

    <input data-testid={`${resourceName}-save`}
           className={saveClassName}
           type="submit"
           value={saveText}/>

    {isLoading && <LoadingElement/>}
    {showUpdateSuccess && <UpdateSuccessElement/>}
    {!!updateError && <ErrorElement>{updateError}</ErrorElement>}
  </form>
}
