import {useTransaction} from "./TransactionContext";
import {RedError} from "../styled-components/RedError";

export function ErrorSection() {
  const {updateError} = useTransaction();

  if (!updateError) return null;

  return <div className="flex col-span-3 justify-start space-x-4 p-3">
    <RedError>
      {updateError}
    </RedError>
  </div>;
}