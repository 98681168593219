import {asUpdateError} from "../../components/v1/form/asUpdateError";

export function deleteOrDeactivateHandlerFor(
  dataObject: { id: number },
  mutator: any,
  setMutateError: (value: (((prevState: string) => string) | string)) => void,
  actionConfirmed: boolean = false
) {
  return async () => {
    setMutateError('');
    if (!actionConfirmed) {
      return
    }
    try {
      await mutator(dataObject.id).unwrap();
      window.location.reload();
    } catch (err: any) {
      setMutateError(asUpdateError(err));
    }
  };
}
