const NumCharsAtEndOfDomain = 7;

export function chopLongGreetings(greeting: string | null | undefined, maxLength: number) {
  if (!greeting) return greeting;

  const [username, mailDomain] = greeting.split('@');
  const combinedLength = greeting.length;

  if (combinedLength > maxLength) {
    const numToRemoveFromEmail = combinedLength - maxLength;

    if (!mailDomain) {
      const [firstName] = username.split(' ');
      const numToRemoveFromFirstName = firstName.length - maxLength;
      return firstName.length < maxLength
        ? firstName
        : `${firstName.slice(0, -numToRemoveFromFirstName)}...`;
    }

    if (numToRemoveFromEmail < username.length + 1) {
      return !!mailDomain
        ? `${username.slice(0, -numToRemoveFromEmail)}...@${mailDomain}`
        : `${username.slice(0, -numToRemoveFromEmail)}...`;
    } else if (numToRemoveFromEmail < mailDomain.length + 8) {
      const [firstLetter, ...rest] = mailDomain.split('');
      return !!mailDomain
        ? `${username}@${firstLetter}...${rest.slice(rest.length - NumCharsAtEndOfDomain).join('')}`
        : username;
    } else {
      return `${greeting.slice(0, maxLength / 2)}...${greeting.slice(greeting.length - maxLength / 2)}`;
    }
  }

  return greeting;
}