import {FieldWithLabelConfig, LabelsToFieldConfigs, ShowFields, TwoChildren} from "./types";
import {RadioProps} from "./Radio";
import {JSX} from "react";
import {prepareConfigToFieldMapper} from "./prepareConfigToFieldMapper";
import {fieldWithLabelReducer} from "./utils";

const NoOpFunc = () => {
};

export type LabelsToFieldsOptions = {
  fieldsWithLabels: FieldWithLabelConfig[],
  resourceName: string,
  state: {},
  setState?: (value: any) => void,
  RadioElement: ({children, ...props}: RadioProps) => JSX.Element,
  showFields?: ShowFields | undefined,
  RowElement: ({children}: TwoChildren) => JSX.Element,
  disabled?: boolean
}

export function labelsToFields(
  {
    fieldsWithLabels,
    resourceName,
    state,
    setState = NoOpFunc,
    RadioElement,
    showFields,
    RowElement,
    disabled = false
  }: LabelsToFieldsOptions
) {
  const labelsToFields: LabelsToFieldConfigs = fieldsWithLabels.reduce(fieldWithLabelReducer, {});

  const toField = prepareConfigToFieldMapper(resourceName, state, setState, RadioElement, disabled);

  return Object
    .entries(labelsToFields)
    .map(([label, configs]) => {
        if (!!showFields && label in showFields && !showFields[label](state)) {
          return null;
        }

        const [, options] = configs[0];

        return <RowElement key={label}>
          <>{label}</>
          {!!options?.radio
            ? <div className={options.className || ''}>{configs.map(toField)}</div>
            : <>{configs.map(toField)}</>}
        </RowElement>;
      }
    );
}