import {Input} from "./Input";

export type RadioProps = {
  forValue: string,
  forStateField: string,
  resourceName: string,
  state: {},
  setState: (value: (((prevState: any) => any) | any)) => void,
  children: any,
  [x: string]: any
};
export const Radio = (
  {forValue, forStateField, resourceName, state, setState, children, ...additionalInputProps}:
    RadioProps
) => {
  return <label>
    <Input
      type="radio"
      data-testid={`${resourceName}-${forValue}`}
      value={forValue}
      checked={(state as any)[forStateField] === forValue}
      forStateField={forStateField}
      resourceName={resourceName}
      state={state}
      setState={setState}
      {...additionalInputProps}
    />
    {children}
  </label>;
};