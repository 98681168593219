import {User} from "../object-control/user";
import {LeftNav, LinkComponentProps} from "./LeftNav";
import {Link} from "react-router-dom";
import {agentLinks, AnyAdminLinks, SuperAdminLinks} from "../../config/siteSettings";
import {FC} from "react";

export const UserLeftNav =
  ({isLeftNavOpen, user, setIsLeftNavOpen}:
     {
       isLeftNavOpen: boolean,
       user: User | null,
       setIsLeftNavOpen: (value: (((prevState: boolean) => boolean) | boolean)) => void
     }) => {
    const toggleLeftNav = () => setIsLeftNavOpen(!isLeftNavOpen);

    if (!user || user?.user_type === 'Customer') {
      return null;
    }

    return <>
      <article onClick={() => setIsLeftNavOpen(false)} className="text-gray-600 lg:hidden p-4 z-10 left-0 fixed">
        <button onClick={event => {
          event.stopPropagation();
          toggleLeftNav();
        }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </article>

      <aside
        className={`md:w-[290px] flex-shrink-0 bg-[#1c2434] text-[#dee4ee] fixed inset-y-0 left-0 lg:translate-x-0 z-[101] transform transition duration-200 ease-in-out ${isLeftNavOpen ? "translate-x-0" : "-translate-x-full"}`}>
        <LeftNavWithLinks
          user={user}
          listComponent={({children}: any) =>
            <ul className="flex flex-col gap-2">
              {children}
            </ul>}
          linkComponent={({children, key, href, ...props}: LinkComponentProps) =>
            <li
              key={key}
              className="group relative flex items-center gap-2 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-[#333a48] ">
              <Link to={href}
                    onClick={() => setIsLeftNavOpen(false)}
                    {...props}>
                {children}
              </Link>
            </li>}/>
      </aside>
    </>;
  };

const LeftNavWithLinks = (
  {
    user,
    listComponent,
    linkComponent
  }:
    {
      user: User | null,
      listComponent: FC<{ children: any }>,
      linkComponent: FC<LinkComponentProps>
    }
) => {

  if (!!user && user.user_type === 'Admin') {
    return <LeftNav
      listComponent={listComponent}
      linkComponent={linkComponent}
      links={SuperAdminLinks}
    />;
  }

  if (!!user && user.user_type === 'TryulaAdmin') {
    return <LeftNav
      listComponent={listComponent}
      linkComponent={linkComponent}
      links={AnyAdminLinks}
    />;
  }

  if (!!user && user.user_type === 'Agent') {
    return <LeftNav
      listComponent={listComponent}
      linkComponent={linkComponent}
      links={agentLinks(user)}
    />;
  }

  return null;
};
