import {Dispatch, ReactNode, SetStateAction, useState} from "react";
import {FullPrivacyPolicyText} from "../../config/siteSettings";

export function useScrollingPrivacyPolicyBox(setPrivacyPolicyConsentFields: Dispatch<SetStateAction<any>>): ReactNode {
  const [
    atEndOfPrivacyPolicyBox,
    setAtEndOfPrivacyPolicyBox
  ] = useState(false);

  return <div>
    <strong>
      Please scroll down and agree to our Terms of Use and Privacy Policy.
    </strong>
    <div
      data-testid="privacy-policy-scrolling-box"
      onScroll={(event) => {
        const atBottomOfPolicy = Math.abs(
            event.currentTarget.scrollHeight
            - (event.currentTarget.scrollTop + event.currentTarget.clientHeight))
          <= 1;
        setAtEndOfPrivacyPolicyBox(atBottomOfPolicy);
      }}
      className="h-36 sm:h-56 text-xs sm:text-sm overflow-y-scroll bg-gray-200 border-2 border-gray-400 p-3">
      {FullPrivacyPolicyText}

      <label className="gap-x-2 block text-center">
        <input
          type="checkbox"
          data-testid="agree-or-disagree"
          className="m-2 hover:cursor-pointer disabled:hover:cursor-not-allowed"
          onClick={() =>
            setPrivacyPolicyConsentFields(
              (prevState: { privacy_policy_consent: any; }) => ({
                ...prevState,
                privacy_policy_consent: !prevState.privacy_policy_consent
              }))}
          disabled={!atEndOfPrivacyPolicyBox}
        />
        I agree
      </label>
    </div>
  </div>;
}
