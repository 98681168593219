import {useCurrentUser} from "../features/auth/useUserData";
import {useEffect} from "react";
import {allow} from "../lib/auth/allow";
import {CreateOrEditAgent} from "../lib/agent/CreateOrEditAgent";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";

export function CreateAgentPage() {
  const {user} = useCurrentUser();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Admin', 'TryulaAdmin'), []);

  return <>
    <PageTitle title="New Agent"/>
    <CreateOrEditAgent agent={null}/>
  </>;
}
