import {UserType} from "../lib/object-control/user";
import {TransactionStatus} from "../lib/object-control/transactionData";
import {TransactionPageView} from "./TransactionsPage";

export type HasNullableTransactionStatus =
  { id: number, transaction_status: TransactionStatus };
type HasUserType = { user_type: UserType };

export const getTransactions = (
  transactions: HasNullableTransactionStatus[] | undefined,
  user: HasUserType | null,
  view?: TransactionPageView
): HasNullableTransactionStatus[] | undefined => {
  if (!transactions) {
    return undefined;
  }

  if (view === 'closed' && user?.user_type === 'Agent') {
    return transactions
      .filter(t => t.transaction_status === 'closed');
  }

  return transactions;
};

export const getStatusFilterValues = (
  user: HasUserType | null,
  view?: TransactionPageView
): string[] => {
  if (user?.user_type === 'Agent' && view === 'closed') {
    return [];
  }

  return ['Active', 'Pending', 'Closed'];
}

export function pendingSinceDecodedOrDefault(pendingSince?: string | null) {
  if (!pendingSince) {
    return 'All';
  }

  return decodeURIComponent(pendingSince);
}