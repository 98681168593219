import {loginHref} from "../utils";
import {User} from "../object-control/user";

export const allow =
  (current_user: User | null, ...allowed_user_types: string[]) =>
    () => {
      if (!current_user
        || !allowed_user_types.includes(current_user.user_type)) {
        window.location.href = loginHref();
      }
    };