import {useSelector} from "react-redux";
import {selectAuthToken, selectCurrentUser} from "./userDataSlice";
import {useMemo} from "react";


export const useCurrentUser = () => {
  const user = useSelector(selectCurrentUser)

  return useMemo(() => ({user}), [user])
}

export const useToken = () => {
  const token = useSelector(selectAuthToken)

  return useMemo(() => ({token}), [token])
}
