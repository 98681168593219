import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: process.env.REACT_APP_AMPLITUDE_SAMPLE_RATE ?? 0.0
});
amplitude.add(sessionReplayTracking);

amplitude.init(
  process.env.REACT_APP_AMPLITUDE_API_KEY ?? '',
  {"autocapture":true}
);
