import {useGetAllLanguagesQuery, useGetAllServiceAreasQuery} from "../services/tryulaApi";
import {RedError} from "../lib/styled-components/RedError";
import {AgentSearch} from "../ui/agent-search/AgentSearch";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";

export function AgentSearchPage() {
  const {
    data: serviceAreas,
    error: serviceAreasError,
    isLoading: areServiceAreasLoading,
  } = useGetAllServiceAreasQuery();

  const {
    data: languages,
    error: languagesError,
    isLoading: areLanguagesLoading,
  } = useGetAllLanguagesQuery();

  if (areServiceAreasLoading || areLanguagesLoading) {
    return <p>Loading available service areas and languages...</p>;
  }
  if (!!serviceAreasError || !serviceAreas) {
    return <RedError>Unknown error loading service areas</RedError>;
  }
  if (!!languagesError || !languages)
    return <RedError>Unknown error loading languages</RedError>;

  return <>
    <PageTitle title="Tryula.com"/>
    <AgentSearch serviceAreas={serviceAreas} languages={languages}/>
  </>;
}