import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {BaseAPIUrl} from "./constants";
import {prepareHeaders} from "./prepareHeaders";
import {ManualTransactionState, TransactionData, TransactionState} from "../lib/object-control/transactionData";
import {Agent, asFormData, CreateOrUpdateAgentRequest, UpdateAgentRequest} from "../lib/object-control/agent";
import {SuperAdmin, SuperAdminState, TryulaAdmin, TryulaAdminState} from "../lib/object-control/admin";
import {PendingEngagement, PendingEngagementState} from "../lib/object-control/pending-engagement";
import {Language, ServiceArea} from "../lib/object-control/language-or-service-area";
import {Customer} from "../lib/object-control/customer";
import {AnyAdminStats, ClosingPriceSum} from "../lib/object-control/stats";

export const tryulaApi = createApi({
  reducerPath: 'tryulaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BaseAPIUrl,
    prepareHeaders
  }),
  endpoints: (builder) => ({
    /* Service Areas */
    getAllServiceAreas: builder.query<ServiceArea[], void>({
      query: () => 'service_areas/',
    }),
    createServiceArea: builder.mutation<ServiceArea | any, { name: string }>({
      query: newServiceAreaState => {
        return ({
          url: 'service_areas/',
          method: 'POST',
          body: newServiceAreaState
        });
      }
    }),
    deleteServiceArea: builder.mutation<any, number>({
      query: id => {
        return ({
          url: `service_areas/${id}/`,
          method: 'DELETE'
        });
      }
    }),

    /* Languages */
    getAllLanguages: builder.query<Language[], void>({
      query: () => 'languages/',
    }),
    createLanguage: builder.mutation<Language | any, { name: string }>({
      query: newLanguageState => {
        return ({
          url: 'languages/',
          method: 'POST',
          body: newLanguageState
        });
      }
    }),
    deleteLanguage: builder.mutation<any, number>({
      query: id => {
        return ({
          url: `languages/${id}/`,
          method: 'DELETE'
        });
      }
    }),

    /* Transactions */
    getAllTransactions: builder.query<TransactionData[], boolean | void>({
      query: (archived?: boolean) =>
        archived ? 'transactions/?archived=true' : 'transactions/',
    }),
    createTransaction: builder.mutation<TransactionData | any, ManualTransactionState>({
      query: (manuallyCreatedTransactionState) => ({
        url: `transactions/`,
        method: 'POST',
        body: manuallyCreatedTransactionState
      })
    }),
    updateTransaction: builder.mutation<TransactionData | any, TransactionState>({
      query: ({id, ...updateTransactionState}) => ({
        url: `transactions/${id}/`,
        method: 'PATCH',
        body: {transaction: updateTransactionState}
      })
    }),

    /* Pending Engagement */
    createPendingEngagement: builder.mutation<PendingEngagement | any, PendingEngagementState>({
      query: newPendingEngagementState => {
        return ({
          url: 'pending_engagements/',
          method: 'POST',
          body: newPendingEngagementState
        });
      }
    }),

    /* Tryula Admins */
    getAllTryulaAdmins: builder.query<TryulaAdmin[], void>({
      query: () => 'tryula_admins/',
    }),
    createTryulaAdmin: builder.mutation<TryulaAdmin | any, TryulaAdminState>({
      query: newTryulaAdminState => {
        return ({
          url: 'tryula_admins/',
          method: 'POST',
          body: newTryulaAdminState
        });
      }
    }),
    deleteTryulaAdmin: builder.mutation<any, number>({
      query: id => {
        return ({
          url: `tryula_admins/${id}/`,
          method: 'DELETE'
        });
      }
    }),

    /* Customers */
    getAllCustomers: builder.query<Customer[], void>({
      query: () => 'customers/',
    }),
    deleteCustomer: builder.mutation<any, number>({
      query: id => {
        return ({
          url: `customers/${id}/`,
          method: 'DELETE'
        });
      }
    }),

    /* Super Admins */
    getAllSuperAdmins: builder.query<SuperAdmin[], void>({
      query: () => 'super_admins/',
    }),
    createSuperAdmin: builder.mutation<SuperAdmin | any, SuperAdminState>({
      query: newSuperAdminState => {
        return ({
          url: 'super_admins/',
          method: 'POST',
          body: newSuperAdminState
        });
      }
    }),
    deleteSuperAdmin: builder.mutation<any, number>({
      query: id => {
        return ({
          url: `super_admins/${id}/`,
          method: 'DELETE'
        });
      }
    }),

    /* Agents CRUD */
    getAllAgents: builder.query<Agent[], void>({
      query: () => 'agents/',
    }),
    getRandomizedAgents: builder.query<Agent[], string>({
      query: (seed: string) => {
        const seedParam = !!seed ? `&seed=${seed}` : '';
        return `agents/?random=true${seedParam}`;
      },
    }),
    getAgentById: builder.query<Agent, string | number>({
      query: id => `agents/${id}/`,
    }),
    getAgentByPowerformId: builder.query<Agent, string>({
      query: agent_powerform_id => `agent_by_powerform_id/${agent_powerform_id}/`,
    }),
    createAgent: builder.mutation<Agent | any, CreateOrUpdateAgentRequest>({
      query: newAgentState => {
        return ({
          url: 'agents/',
          method: 'POST',
          body: asFormData(newAgentState),
          formData: true
        });
      }
    }),
    updateAgent: builder.mutation<Agent | any, UpdateAgentRequest>({
      query: ({id, ...updateAgentState}) =>
        ({
          url: `agents/${id}/`,
          method: 'PATCH',
          body: asFormData(updateAgentState),
          formData: true
        })
    }),
    resendAgentInvite: builder.mutation<
      { message: string } | { error: string },
      string | number
    >({
      query: id => ({
        url: `agents/${id}/resend_invite`,
        method: 'PATCH'
      }),
    }),
    deleteAgent: builder.mutation<any, number>({
      query: id => {
        return ({
          url: `agents/${id}/`,
          method: 'DELETE'
        });
      }
    }),
    deactivateAgent: builder.mutation<any, number>({
      query: id => {
        return ({
          url: `agents/${id}/`,
          method: 'PATCH',
          body: {opt_out: true}
        });
      }
    }),

    getPrivacyPolicyRequired: builder.query<{
      privacy_policy_consent_required: boolean,
      user_email: string
    }, string>({
      query: reset_password_token => `privacy_check/${reset_password_token}/`
    }),

    getAnyAdminStats: builder.query<AnyAdminStats, void>({
      query: () => 'any_admin_stats/'
    }),
    getSuperAdminStats: builder.query<{
      yearly_closed: ClosingPriceSum[],
      monthly_closed: ClosingPriceSum[],
      all_time_closed: number,
      ytd_closed: number,
      lt30d_closed: number,
      lt60d_closed: number,
      lt90d_closed: number
    }, void>({
      query: () => 'super_admin_stats/'
    }),

    getAllLoginFailures: builder.query<{ email: string, created_at: string }[], void>({
      query: () => 'login_failures/',
    }),

    getKeepAlive: builder.query({
      query: () => 'keep_alives/'
    })
  })
});

export const {
  useGetAllServiceAreasQuery,
  useCreateServiceAreaMutation,
  useDeleteServiceAreaMutation,

  useGetAllLanguagesQuery,
  useCreateLanguageMutation,
  useDeleteLanguageMutation,

  useGetAllTransactionsQuery,
  useCreateTransactionMutation,
  useUpdateTransactionMutation,

  useCreatePendingEngagementMutation,

  useGetAllTryulaAdminsQuery,
  useCreateTryulaAdminMutation,
  useDeleteTryulaAdminMutation,

  useGetAllSuperAdminsQuery,
  useCreateSuperAdminMutation,
  useDeleteSuperAdminMutation,

  useGetAllCustomersQuery,
  useDeleteCustomerMutation,

  useGetAllAgentsQuery,
  useGetRandomizedAgentsQuery,
  useGetAgentByIdQuery,
  useGetAgentByPowerformIdQuery,
  useCreateAgentMutation,
  useUpdateAgentMutation,
  useResendAgentInviteMutation,
  useDeleteAgentMutation,
  useDeactivateAgentMutation,

  useGetPrivacyPolicyRequiredQuery,

  useGetAnyAdminStatsQuery,
  useGetSuperAdminStatsQuery,

  useGetAllLoginFailuresQuery,
  useGetKeepAliveQuery
} = tryulaApi;
