import * as PropTypes from "prop-types";
import AvatarUploader from "./avatar-uploader/AvatarUploader";

export const AvatarEditor =
  ({onSaveUserPhoto, avatarURL, viewer}) => {
    const imagePreview = <div className="mb-4 flex items-center gap-3">
      <div className="h-42 w-42 rounded-full">
        <img
          data-testid="avatar-url-receiver"
          className="rounded-full aspect-square w-[168px] h-[168px]"
          src={avatarURL}
          alt=""
        />
      </div>
    </div>;

    return <div
      data-testid="user-photo"
      className="panelWhite task relative flex rounded-sm border border-stroke bg-white p-7 shadow-default flex-col gap-5">
      <div className="flex flex-row items-center gap-10">
        <p className="text-md font-semibold">{viewer === 'agent' ? 'Your Photo' : 'Agent Photo'}</p>
        <hr className="flex-grow"/>
      </div>

      {viewer === 'agent'
        ? <AvatarUploader onSaveUserPhoto={onSaveUserPhoto}>
          {imagePreview}
        </AvatarUploader>
        : imagePreview}
    </div>;
  };

AvatarEditor.propTypes = {
  onSaveUserPhoto: PropTypes.func,
  avatarURL: PropTypes.string,
  viewer: PropTypes.string
};