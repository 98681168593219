import {useLocation} from "react-router-dom";
import {useEffect} from "react";

export function PageTitle({title}: { title: string }) {
  const location = useLocation();

  useEffect(() => {
    document.title = title;
  }, [location, title]);

  return <div data-testid="title-stub"></div>;
}