import {textInputStateUpdater} from "./utils";
import {NumericFormat} from "react-number-format";

export type SetState = (value: (((prevState: any) => any) | any)) => void;
type InputProps = {
  forStateField: string,
  state: {},
  setState: SetState,
  [x: string]: any;
  resourceName?: string,
  textarea?: boolean,
  price?: boolean
  percent?: boolean
};

export function getInputTestId(forStateField: string, resourceName?: string | undefined | null) {
  if (!resourceName) {
    return forStateField;
  }

  return `${resourceName}-${forStateField}`;
}

export const Input = (
  {forStateField, resourceName, state, setState, textarea = false, price = false, percent = false, ...additionalInputProps}:
    InputProps
) => {
  if (textarea) {
    return <textarea
      name={forStateField}
      data-testid={getInputTestId(forStateField, resourceName)}
      defaultValue={(state as any)[forStateField] || ''}
      onChange={textInputStateUpdater(state, setState)}
      {...additionalInputProps}
    ></textarea>
  }

  if (price) {
    const priceProps =
      additionalInputProps?.disabled
        ? {
          value: Number((state as any)[forStateField] || 0).toFixed(0)
        } : {
          defaultValue: Number((state as any)[forStateField] || 0).toFixed(0)
        };

    return <NumericFormat
      thousandSeparator={true}
      prefix={'$'}

      name={forStateField}
      data-testid={getInputTestId(forStateField, resourceName)}
      onChange={textInputStateUpdater(state, setState)}
      onFocus={event => event.target.select()}
      {...priceProps}
      {...additionalInputProps}/>
  }

  if (percent) {
    const percentProps =
      additionalInputProps?.disabled
        ? {
          value: Number((state as any)[forStateField] || 0).toFixed(1)
        } : {
          defaultValue: Number((state as any)[forStateField] || 0).toFixed(1)
        };

    return <NumericFormat
      suffix={'%'}

      name={forStateField}
      data-testid={getInputTestId(forStateField, resourceName)}
      onChange={textInputStateUpdater(state, setState)}
      onFocus={event => {
        if (event.target.value !== '0.0%') {
          return;
        }
        event.target.value = '';
      }}
      {...percentProps}
      {...additionalInputProps}/>
  }

  return <input
    name={forStateField}
    type="text"
    data-testid={getInputTestId(forStateField, resourceName)}
    value={(state as any)[forStateField] || ''}
    onChange={textInputStateUpdater(state, setState)}
    {...additionalInputProps}
  />;
};

export const Password = (props: InputProps) =>
  <Input {...props} type="password"/>;
