import {useRef, useState} from 'react'

import ReactCrop, {centerCrop, makeAspectCrop,} from 'react-image-crop'
import {canvasPreview} from './canvasPreview'
import {useDebounceEffect} from './useDebounceEffect'

import 'react-image-crop/dist/ReactCrop.css'
import * as PropTypes from "prop-types";
import {TailwindTwoColumnGrid} from "../../styled-components/TailwindTwoColumnGrid";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const scale = 1
const rotate = 0
const aspect = 12 / 12

export default function AvatarUploader({
                                         onSaveUserPhoto,
                                         children
                                       }) {
  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const hiddenAnchorRef = useRef(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const {width, height} = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  async function onSaveProfileImage() {
    const image = imgRef.current
    const previewCanvas = previewCanvasRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height,
    )
    // You might want { type: "image/jpeg", quality: <0 to 1> } to
    // reduce image size
    const blob = await offscreen.convertToBlob({
      type: 'image/jpeg',
    })
    const filesFromBlob = {
      files: [new File([blob], `${crypto.randomUUID()}.jpeg`)],
    };

    if (blobUrlRef.current) {
      URL.revokeObjectURL(blobUrlRef.current)
    }
    blobUrlRef.current = URL.createObjectURL(blob)

    if (hiddenAnchorRef.current) {
      hiddenAnchorRef.current.href = blobUrlRef.current
      // setCurrentImage(filesFromBlob)
      // setAvatarURL(blobUrlRef.current)
      onSaveUserPhoto(filesFromBlob, blobUrlRef.current)
    }
  }

  useDebounceEffect(
    async (completedCrop, scale, rotate) => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  const imagePreview = children;

  return (
    <TailwindTwoColumnGrid>
      <div className="Crop-Controls col-span-2 sm:col-span-1 space-y-3">
        {imagePreview}

        <input
          id="CropFileInput"
          data-testid="crop-file-input"
          type="file"
          accept="image/*"
          onChange={onSelectFile}
        />

        {!!completedCrop && <div>
          <button
            className="ml-1 bg-[#27AAE1] hover:bg-blue-500 text-white font-bold px-4 py-1 rounded"
            onClick={onSaveProfileImage}>
            Save Crop
          </button>
          <a
            href="#hidden"
            ref={hiddenAnchorRef}
            download
            style={{
              position: 'absolute',
              top: '-200vh',
              visibility: 'hidden',
            }}
          >
            Hidden download
          </a>
        </div>}
      </div>

      <div className="col-span-2 mt-3 sm:col-span-1 sm:mt-0">
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            // minWidth={400}
            minHeight={100}
            // circularCrop
          >
            <img
              data-testid="cropper-img"
              ref={imgRef}
              alt="Crop me"
              src={imgSrc}
              style={{transform: `scale(${scale}) rotate(${rotate}deg)`}}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}

        {!!completedCrop && <canvas
          ref={previewCanvasRef}
          style={{
            display: "none",
            // border: '1px solid black',
            objectFit: 'contain',
            width: completedCrop.width,
            height: completedCrop.height,
          }}
        />}
      </div>
    </TailwindTwoColumnGrid>
  )
};

AvatarUploader.propTypes = {
  onSaveUserPhoto: PropTypes.func,
}
