import {CheckboxState} from "../filter/types";
import {isChecked, prepareToggleHandler} from "./toggleViewerAndHandler";

export function getFilterCheckboxes(selectionName: string, selectedValues: CheckboxState, setSelectedValues: (value: CheckboxState) => void) {
  return Object.keys(selectedValues).map((value: string, i: number) =>
    <FilterCheckbox
      selectionName={selectionName}
      key={i}
      value={value}
      checked={isChecked(value, selectedValues)}
      handleToggleValue={prepareToggleHandler({selectedValues, setSelectedValues})}
    />
  );
}

type HandleToggleValue = (checked: boolean, value: string) => void;

interface FilterCheckboxProps {
  value: string,
  checked: boolean,
  handleToggleValue: HandleToggleValue,
  selectionName: string
}

const FilterCheckbox = ({checked, value, handleToggleValue, selectionName}: FilterCheckboxProps) => <label
>
  <input
    data-testid={`${selectionName}-${value}`}
    className="me-1"
    type="checkbox"
    value={value}
    checked={checked}
    onChange={() => handleToggleValue(checked, value)}
  />
  {value}
</label>;