import './App.css';
import {ToastContainer} from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import {UserApp} from "./UserApp";

function App() {
  return (
    <div className="App">
      <ToastContainer/>
      <UserApp/>
    </div>
  );
}

export default App;
