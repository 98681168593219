import {useGetAllLoginFailuresQuery, useGetAnyAdminStatsQuery} from "../services/tryulaApi";
import {RedError} from "../lib/styled-components/RedError";
import {AnyAdminStats} from "../lib/object-control/stats";
import {useCurrentUser} from "../features/auth/useUserData";

function LoginFailuresForSuperAdmin() {
  const {
    data: loginFailures,
    error: loginFailuresFetchError,
    isLoading: isLoginFailuresLoading
  } = useGetAllLoginFailuresQuery();

  if (!!loginFailuresFetchError) {
    return <RedError>Error returning login failures</RedError>;
  }

  if (isLoginFailuresLoading) {
    return <>Loading login failures...</>;
  }

  return <div className="col-span-3">
    <h3 className="text-lg font-semibold mt-3 mb-3">Most recent login failures</h3>
    <ul className="space-y-3">
      {!!loginFailures
        ? <>{loginFailures.map((lf, i) =>
          <li key={i} className="grid grid-cols-5">
            <p className="col-span-2 break-words">{lf.email}</p>
            <p className="col-span-1 text-center">at</p>
            <p className="col-span-2 text-end">{lf.created_at}</p>
          </li>)}</>
        : <li>No login failures</li>}
    </ul>
  </div>;
}

export function AdminStats() {
  const {user} = useCurrentUser();

  // const primaryAxis = React.useMemo(
  //   (): AxisOptions<ClosingPriceSum> => ({
  //     getValue: datum => datum.date,
  //   }),
  //   []
  // );
  // const secondaryAxes = React.useMemo(
  //   (): AxisOptions<ClosingPriceSum>[] => [
  //     {
  //       getValue: datum => datum.dollars,
  //       formatters: {
  //         tooltip: inDollars,
  //       }
  //     },
  //   ],
  //   []
  // );

  const {
    data: anyAdminStats,
    error: anyAdminStatsError,
    isLoading: isAnyAdminStatsLoading
  } = useGetAnyAdminStatsQuery();
  // const {
  //   data: superAdminStats,
  //   error: superAdminStatsError,
  //   isLoading: isSuperAdminStatsLoading
  // } = useGetSuperAdminStatsQuery();

  if (!!anyAdminStatsError) {
    return <RedError>Unknown error fetching admin stats bundle 1</RedError>;
  }
  if (isAnyAdminStatsLoading || !anyAdminStats) {
    return <p>Loading admin stats bundle 1...</p>
  }

  // if (!!superAdminStatsError
  //   && 'status' in superAdminStatsError
  //   && superAdminStatsError.status !== 401) {
  //   console.log(superAdminStatsError);
  //   return <RedError>Unknown error fetching admin stats bundle 2</RedError>;
  // }
  // if (isSuperAdminStatsLoading) {
  //   return <p>Loading admin stats bundle 2...</p>
  // }

  // const monthlyData: Series[] = !superAdminStats ? [] : [
  //   {
  //     label: 'Dollars per month',
  //     data: superAdminStats?.monthly_closed || []
  //   }
  // ];
  // const yearlyData: Series[] = !superAdminStats ? [] : [
  //   {
  //     label: 'Dollars per year',
  //     data: superAdminStats?.yearly_closed || []
  //   }
  // ];

  return <>
    <div className="grid grid-cols-3 gap-4">
      <a href="/transactions?transaction_status=active"
         data-testid="active-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-green-400 rounded-lg shadow hover:bg-green-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          Active
        </h5>

        <p
          data-testid="num-active-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).all_time_active}
        </p>
      </a>

      <a href="/transactions?transaction_status=pending"
         data-testid="pending-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-yellow-400 rounded-lg shadow hover:bg-yellow-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          Pending
        </h5>

        <p
          data-testid="num-pending-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).all_time_pending}
        </p>
      </a>

      <a href="/transactions?transaction_status=closed"
         data-testid="closed-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-red-400 rounded-lg shadow hover:bg-red-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          Closed
        </h5>

        <p
          data-testid="num-closed-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).all_time_closed}
        </p>
      </a>

      {/*>45 days*/}
      <a href="/transactions?pending_date=%3E45%20days&transaction_status=pending"
         data-testid="gt45d-stat-panel"
         className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-red-400 rounded-lg shadow hover:bg-red-300">
        <h5
          className="mb-2 text-2xl font-bold text-black dark:text-white">
          {">"} 45 days
        </h5>

        <p
          data-testid="num-gt45d-transactions"
          className="font-semibold text-black dark:text-white">
          {(anyAdminStats as AnyAdminStats).gt45d_pending}
        </p>
      </a>

      {user?.user_type === 'Admin' && <LoginFailuresForSuperAdmin/>}

      {/*wishlist*/}
      {/*{!!superAdminStats && <>*/}
      {/*  <div data-testid="super-admin-monthly-closed" className="col-span-3">*/}
      {/*    <h5>Closing data by month</h5>*/}
      {/*    <div className="block w-full h-56">*/}
      {/*      <Chart options={{*/}
      {/*        data: monthlyData,*/}
      {/*        primaryAxis,*/}
      {/*        secondaryAxes*/}
      {/*      }}/>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div data-testid="super-admin-yearly-closed" className="col-span-3">*/}
      {/*    <h5>Closing data by year</h5>*/}
      {/*    <div className="block w-full h-56">*/}
      {/*      <Chart options={{*/}
      {/*        data: yearlyData,*/}
      {/*        primaryAxis,*/}
      {/*        secondaryAxes*/}
      {/*      }}/>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div*/}
      {/*    data-testid="super-admin-all-time-closed"*/}
      {/*    className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-green-400 rounded-lg shadow hover:bg-green-300">*/}
      {/*    <h5 className="mb-2 text-2xl font-bold text-black dark:text-white">*/}
      {/*      All time closed*/}
      {/*    </h5>*/}

      {/*    <p className="font-semibold text-black dark:text-white">*/}
      {/*      {inDollars(superAdminStats.all_time_closed)}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    data-testid="super-admin-ytd-closed"*/}
      {/*    className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-green-400 rounded-lg shadow hover:bg-green-300">*/}
      {/*    <h5 className="mb-2 text-2xl font-bold text-black dark:text-white">*/}
      {/*      YTD closed*/}
      {/*    </h5>*/}

      {/*    <p className="font-semibold text-black dark:text-white">*/}
      {/*      {inDollars(superAdminStats.ytd_closed)}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-green-400 rounded-lg shadow hover:bg-green-300">*/}
      {/*    <h5 className="mb-2 text-2xl font-bold text-black dark:text-white">*/}
      {/*      Last 30d closed*/}
      {/*    </h5>*/}

      {/*    <p*/}
      {/*      data-testid="super-admin-last-30d-closed"*/}
      {/*      className="font-semibold text-black dark:text-white">*/}
      {/*      {inDollars(superAdminStats.lt30d_closed)}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-green-400 rounded-lg shadow hover:bg-green-300">*/}
      {/*    <h5 className="mb-2 text-2xl font-bold text-black dark:text-white">*/}
      {/*      Last 60d closed*/}
      {/*    </h5>*/}

      {/*    <p*/}
      {/*      data-testid="super-admin-last-60d-closed"*/}
      {/*      className="font-semibold text-black dark:text-white">*/}
      {/*      {inDollars(superAdminStats.lt60d_closed)}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*  <div*/}
      {/*    className="text-center col-span-3 sm:col-span-1 max-w-sm p-4 bg-green-400 rounded-lg shadow hover:bg-green-300">*/}
      {/*    <h5 className="mb-2 text-2xl font-bold text-black dark:text-white">*/}
      {/*      Last 90d closed*/}
      {/*    </h5>*/}

      {/*    <p*/}
      {/*      data-testid="super-admin-last-90d-closed"*/}
      {/*      className="font-semibold text-black dark:text-white">*/}
      {/*      {inDollars(superAdminStats.lt90d_closed)}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</>}*/}
    </div>
  </>;
}