import {ReactNode, useCallback, useEffect} from "react";

type ModalProps = {
  show: boolean,
  setShow: (value: (((prevState: boolean) => boolean) | boolean)) => void,
  children: [ReactNode, ReactNode, ReactNode] | [ReactNode, ReactNode, ReactNode, ReactNode]
};

export function Modal({children, setShow, show}: ModalProps) {
  const escFunction = useCallback((event: { key: string; }) => {
    if (event.key === "Escape") {
      setShow(false)
    }
  }, [setShow]);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  if (!show) return null;

  const [header, content, actionLink, optionalAsteriskText] = children;

  return <>
    <div className="opacity-25 fixed inset-0 z-40 bg-black"/>
    <div
      className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div
        className="relative w-auto my-6 mx-auto max-w-3xl">
        <div
          className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">

          <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            {header}
          </div>

          <div className="relative p-6 flex-auto">
            {content}
          </div>

          <div className="flex items-center justify-between p-3 border-t border-solid border-blueGray-200 rounded-b">
            <div>
              {!!optionalAsteriskText
                && <small className="text-xs pl-3 pb-1">{optionalAsteriskText}</small>}
            </div>

            <div>
              <button
                data-testid="engage-close-button"
                onClick={() => setShow(false)}
                className="background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button">
                Close
              </button>
              {actionLink}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;
}