import {createContext, Dispatch, SetStateAction, useContext} from "react";
import {CanEditFunc, CanViewFunc} from "./utils";
import {TransactionData, TransactionState} from "../object-control/transactionData";
import {MutationDefinition} from "@reduxjs/toolkit/query";
import {MutationTrigger} from "@reduxjs/toolkit/dist/query/react/buildHooks";

export type TransactionActionsAndState = {
  canEdit: CanEditFunc;
  canView: CanViewFunc;
  updateError: string | undefined;
  setUpdateError: Dispatch<SetStateAction<string>>;
  updateSuccess: boolean;
  setUpdateSuccess: Dispatch<SetStateAction<boolean>>;
  transactionState: TransactionState;
  setTransactionState: Dispatch<SetStateAction<TransactionState>>;
  referralAgreementUrl: string;
  updateTransaction:
    MutationTrigger<MutationDefinition<TransactionState, any, any, TransactionData>> | undefined;
  refetch: () => void;
};

const defaultTransactionContextState: TransactionActionsAndState = {
  canEdit: () => false,
  canView: () => false,
  updateError: undefined,
  setUpdateError: () => undefined,
  updateSuccess: false,
  setUpdateSuccess: () => undefined,
  transactionState: {},
  setTransactionState: () => undefined,
  referralAgreementUrl: '',
  updateTransaction: undefined,
  refetch: () => undefined,
};

export const TransactionContext = createContext(defaultTransactionContextState);
export const useTransaction =
  (): TransactionActionsAndState & { transaction: TransactionState } => {
    const context = useContext(TransactionContext);

    if (!context) throw new Error('Transaction utilities must be used within Transaction.Root');
    if (!context.transactionState) throw new Error('Transaction not defined');

    return {...context, transaction: context.transactionState};
  }