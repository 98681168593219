import {Input} from "../lib/johncornish/components/v1/form/Input";
import {getStatusFilterValues} from "./transactions-page-utils";
import {FC, useState} from "react";
import {TransactionPageView} from "./TransactionsPage";
import {User} from "../lib/object-control/user";

export type TransactionFilter = {
  customer_name_or_customer_email: string;
  transaction_status: string;
  address: string;
  pending_date: string;
  transaction_type: string
};

interface TransactionSearchBoxProps {
  handleFilterType: (event: { target: { value: string } }) => void,
  filter: TransactionFilter,
  setFilter: (value: (((prevState: TransactionFilter) => TransactionFilter) | TransactionFilter)) => void,
  adminDateFilter: null | React.JSX.Element,
  view?: TransactionPageView,
  handleFilterStatus: (event: { target: { value: string } }) => void,
  urlFilteredTransactionStatus: string | null,
  user: User | null,
  initialFilter: TransactionFilter
}

export const TransactionSearchBox: FC<TransactionSearchBoxProps> =
  ({
     handleFilterType,
     filter,
     setFilter,
     adminDateFilter,
     view,
     handleFilterStatus,
     urlFilteredTransactionStatus,
     user,
     initialFilter
   }) => {
    const [showSearchFilter, setShowSearchFilter] = useState(false);

    return <>{
      showSearchFilter
        ? <>
          <div className="transaction-filter-box grid grid-cols-2 bg-white p-5 mb-3 border outline-neutral-700 rounded">
            <h3 className={'title'}>
              Search Transactions

              <button
                data-testid={'close-search-box'}
                onClick={() => {
                  setShowSearchFilter(false);
                  setFilter(initialFilter);
                }}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                     stroke="currentColor" className="size-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12"/>
                </svg>
              </button>
            </h3>
            <div className="col-span-1 pr-2">
              <div>
                <label className="text-[#000]">
                  Transaction Type
                  <select data-testid="select-transaction-type"
                          className="p-2 w-full bg-white border outline-neutral-700"
                          onChange={handleFilterType}>
                    <option data-testid="filter-type-option-All" value="">
                      All
                    </option>
                    <option data-testid="filter-type-option-buy" value="buy">
                      buy
                    </option>
                    <option data-testid="filter-type-option-sell" value="sell">
                      sell
                    </option>
                  </select>
                </label>
              </div>

              <div className="mt-3">
                <label>
                  Customer Name or Email
                  <Input className="border w-full p-1" forStateField="customer_name_or_customer_email" state={filter}
                         setState={setFilter}/>
                </label>
              </div>

              {adminDateFilter}
            </div>
            <div className="col-span-1">
              <div>
                {view !== 'closed' && <div className="mb-3">
                  <label className="text-[#000]">
                    Transaction Status
                    <select data-testid="select-transaction-status"
                            className="p-2 w-full bg-white border outline-neutral-700"
                            onChange={handleFilterStatus}
                            defaultValue={urlFilteredTransactionStatus || undefined}>
                      <option data-testid="filter-status-option-All" value="">
                        All
                      </option>
                      {getStatusFilterValues(user, view)
                        .map(status =>
                          <option data-testid={`filter-status-option-${status.toLowerCase()}`}
                                  value={status.toLowerCase()}>
                            {status}
                          </option>)}
                    </select>
                  </label>
                </div>}

                <div>
                  <label>
                    Address
                    <Input className="border w-full p-1" forStateField="address" state={filter} setState={setFilter}/>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr className={'filter-and-content-separator'}/>
        </>
        : <section className={'show-search-transactions'}>
          <button
            className="text-white bg-[#1f4883] hover:bg-[#1f4883AB] py-2 px-4 rounded"
            data-testid={'action-open-search-transactions'}
            onClick={() => setShowSearchFilter(true)}>
            Search Transactions
          </button>
        </section>
    }
    </>;
  };