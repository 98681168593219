import {StateValidators} from "../johncornish/components/v1/form/types";
import {isPrice} from "../utils";

function skipValidation(submitterValue: 'Archive' | 'Unarchive' | 'Unlock' | 'Reset to Active' | 'Save'): boolean {
  if (submitterValue === 'Archive') {
    return true;
  }

  if (submitterValue === 'Unarchive') {
    return true;
  }

  if (submitterValue === 'Unlock') {
    return true;
  }

  if (submitterValue === 'Reset to Active') {
    return true;
  }

  return false;
}

export const validators: StateValidators = [
  ['transaction_type',
    ({transaction_type}, submitterValue) =>
      skipValidation(submitterValue)
      || transaction_type === 'buy'
      || transaction_type === 'sell',
    'You must select "Buy" or "Sell"'],
  ['customer_name',
    ({customer_name}, submitterValue) =>
      skipValidation(submitterValue)
      || !!customer_name,
    "You must provide the customer's name"
  ],
  ['address',
    ({transaction_type, transaction_status, address}, submitterValue) =>
      skipValidation(submitterValue)
      || (transaction_type === 'buy' && transaction_status === 'active')
      || !!address,
    "You must provide the address"
  ],
  ['mls_number',
    ({transaction_status, mls_number}, submitterValue) =>
      skipValidation(submitterValue)
      || transaction_status === 'active'
      || (!!mls_number && !isNaN(mls_number)),
    "You must provide the MLS #"
  ],
  ['pending_price',
    ({transaction_status, pending_price}, submitterValue) =>
      skipValidation(submitterValue)
      || transaction_status !== 'pending'
      || isPrice(pending_price),
    "You must set the pending price"
  ],
  ['pending_date',
    ({transaction_status, pending_date}, submitterValue) =>
      skipValidation(submitterValue)
      || transaction_status !== 'pending'
      || !!pending_date,
    "You must set the pending date"
  ],
  ['closing_price',
    ({transaction_status, closing_price}, submitterValue) =>
      skipValidation(submitterValue)
      || transaction_status !== 'closed'
      || isPrice(closing_price),
    "You must set the closing price"
  ],
  ['compensation_rate',
    ({transaction_status, compensation_rate}, submitterValue) =>
      skipValidation(submitterValue)
      || transaction_status !== 'closed'
      || !!compensation_rate,
    "You must set the compensation rate"
  ],
  ['closing_date',
    ({transaction_status, closing_date}, submitterValue) =>
      skipValidation(submitterValue)
      || transaction_status !== 'closed'
      || !!closing_date,
    "You must set the closing date"
  ],
];