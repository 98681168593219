import {TwoChildren} from "../johncornish/components/v1/form/types";

export const ProfileFieldRow = ({children}: TwoChildren) => {
  const [label, field] = children;

  return <div className="input-item-wrap flex flex-col flex-grow text-sm font-normal text-black">
    <label
      className="block"
    >
      {label}
    </label>

    <div className="relative">
      {field}
    </div>
  </div>
};