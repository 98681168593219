import {MutateError} from "./types";

export function asUpdateError(response: MutateError) {
  // @ts-ignore
  if (!!response.error) {
    // @ts-ignore
    return response.error;
  }

  // @ts-ignore
  const error = response.data?.error;
  if (error && typeof error === 'string') return error;

  // @ts-ignore
  if (error && Array.isArray(error)) return error.join(', ');

  // @ts-ignore
  const errorMessage = response.data?.error?.message
  if (errorMessage && typeof errorMessage === 'string') return errorMessage;

  // @ts-ignore
  const statusMessage = response.data?.status?.message
  if (statusMessage) return statusMessage;

  // @ts-ignore
  if (Array.isArray(response.data)) return response.data.join(', ');

  // @ts-ignore
  if (typeof response.data === 'object') {
    // @ts-ignore
    return Object.entries(response.data).map(([key, errors]) => {
      if (!Array.isArray(errors)) {
        return ''
      }

      return `${key.replaceAll('_', ' ')}: ${errors.join(', ')}`;
    }).join('; ');
  }

  console.error(response);
  return ''
}