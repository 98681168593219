import {FieldWithLabelConfig, StateValidators} from "../johncornish/components/v1/form/types";
import {CheckboxState} from "../johncornish/components/v1/filter/types";

interface BaseAgentFields {
  email: string;
  first_name: string;
  last_name: string;
  published: boolean;
  phone_number: string;
  agent_license: string;
  brokerage_office_name: string;
  brokerage_license: string;
  powerform_url: string;
  powerform_id: string;
}

export interface Agent extends BaseAgentFields {
  id: number;
  bio: string;
  service_areas: string[];
  languages: string[];
  privacy_policy_consent: boolean;
  opt_out: boolean;
  referral_agreement_url?: string | null;
  avatar_url?: string | null;
}

export interface AgentCreatingState extends BaseAgentFields {
  referral_agreement?: File;
}

export interface UpdateAgentProfileState extends BaseAgentFields {
  avatar?: File;
  avatar_url?: string | null;
  bio: string;
  service_areas: string[];
  languages: string[];
  privacy_policy_consent: boolean;
}

export interface UpdateAgentRequest extends BaseAgentFields {
  id: number;
  referral_agreement: File;
  privacy_policy_consent: boolean;
  opt_out?: boolean;
}

export interface OptOutAgentRequest {
  id: number;
  opt_out: boolean;
}

export const InitialCreateAgentState: AgentCreatingState = {
  first_name: '',
  last_name: '',
  published: false,
  email: '',
  phone_number: '',
  agent_license: '',
  brokerage_office_name: '',
  brokerage_license: '',
  powerform_url: '',
  powerform_id: ''
};

export interface CreateOrUpdateAgentRequest extends BaseAgentFields {
  referral_agreement?: File;
  avatar?: File;
  bio?: string;
  service_areas?: string[];
  languages?: string[];
  privacy_policy_consent: boolean;
  opt_out?: boolean;
}

export function asFormData(agentState: CreateOrUpdateAgentRequest): FormData {
  const formData = new FormData();
  if (!!agentState.first_name)
    formData.append('agent[first_name]', agentState.first_name);

  if (!!agentState.last_name)
    formData.append('agent[last_name]', agentState.last_name);

  if (!!agentState.email)
    formData.append('agent[email]', agentState.email);

  if (!!agentState.phone_number)
    formData.append('agent[phone_number]', agentState.phone_number);

  if (!!agentState.agent_license)
    formData.append('agent[agent_license]', agentState.agent_license);

  if (!!agentState.brokerage_office_name)
    formData.append('agent[brokerage_office_name]', agentState.brokerage_office_name);

  if (!!agentState.brokerage_license)
    formData.append('agent[brokerage_license]', agentState.brokerage_license);

  if (!!agentState.powerform_url)
    formData.append('agent[powerform_url]', agentState.powerform_url);

  if (!!agentState.powerform_id)
    formData.append('agent[powerform_id]', agentState.powerform_id);

  if (!!agentState.bio)
    formData.append('agent[bio]', agentState.bio);

  if (agentState.privacy_policy_consent !== undefined)
    formData.append('agent[privacy_policy_consent]',
      `${agentState.privacy_policy_consent}`);

  if (agentState.published !== undefined)
    formData.append('agent[published]', `${agentState.published}`);

  if (agentState.opt_out !== undefined)
    formData.append('agent[opt_out]', `${agentState.opt_out}`);

  if (!!agentState.referral_agreement)
    formData.append('agent[referral_agreement]', agentState.referral_agreement);
  if (!!agentState.avatar)
    formData.append('agent[avatar]', agentState.avatar);

  if (!!agentState.service_areas) {
    for (const serviceArea of agentState.service_areas) {
      formData.append('agent[service_areas][]', serviceArea);
    }
  }
  if (!!agentState.languages) {
    for (const language of agentState.languages) {
      formData.append('agent[languages][]', language);
    }
  }

  return formData;
}

export const AgentFieldStyle = "w-full rounded border border-stroke bg-[#eff4fb] p-[10px] pl-[25px] font-normal text-black focus:border-primary focus-visible:outline-none disabled:bg-gray-100 disabled:text-gray-500";

export const CreateOrEditAgentFields: FieldWithLabelConfig[] = [
  ['First Name', 'first_name', {
    placeholder: 'John',
    className: AgentFieldStyle
  }],
  ['Last Name', 'last_name', {
    placeholder: 'Doe',
    className: AgentFieldStyle
  }],
  ['Email', 'email', {
    placeholder: 'new-agent@example.com',
    className: AgentFieldStyle
  }],
  ['Phone', 'phone_number', {
    placeholder: '123-456-7890',
    className: AgentFieldStyle,
    tel: true
  }],
  ['Powerform URL', 'powerform_url', {
    placeholder: 'https://na4.docusign.net/...',
    className: AgentFieldStyle
  }],
  ['Referral Agreement', 'referral_agreement', {
    file: true,
    className: AgentFieldStyle
  }],
];

export const AgentEditProfileFields: FieldWithLabelConfig[] = [
  ['First Name', 'first_name', {
    placeholder: 'John',
    className: AgentFieldStyle
  }],
  ['Last Name', 'last_name', {
    placeholder: 'Doe',
    className: AgentFieldStyle
  }],
  ['Email', 'email', {
    placeholder: 'new-agent@example.com',
    className: AgentFieldStyle
  }],
  ['Phone', 'phone_number', {
    placeholder: '123-456-7890',
    tel: true,
    className: AgentFieldStyle
  }],
  ['Agent License', 'agent_license', {
    placeholder: 'IR123467890',
    className: AgentFieldStyle
  }],
  ['Brokerage Office Name', 'brokerage_office_name', {
    placeholder: 'Tryula Realty',
    className: AgentFieldStyle
  }],
  ['Brokerage License', 'brokerage_license', {
    placeholder: 'RO123456789',
    className: AgentFieldStyle
  }],
  ['Bio', 'bio', {
    textarea: true,
    className: "w-full rounded border border-stroke bg-[#eff4fb] p-[10px] pl-[25px] font-normal text-black focus:border-primary focus-visible:outline-none disabled:bg-gray-100 disabled:text-gray-500 h-48",
    maxLength: 1_000
  }],
];

export const validPhoneNumber = ({phone_number}: { phone_number: string; }) => {
  const tenDigits = new RegExp(/^\d{10}$/);
  const onlyNumbers = phone_number.replace(/[^0-9]/g, '');
  return tenDigits.test(onlyNumbers);
};

export const ValidateUpdateAgentProfileFields: StateValidators = [
  ['first_name', state => !!state.first_name, 'First name is required'],
  ['last_name', state => !!state.last_name, 'Last name is required'],
  ['email', state => !!state.email, 'Email is required'],
  ['phone_number', validPhoneNumber,
    'Valid phone number is required (only 10 digits, no country code)'],
  ['agent_license',
    state => !!state.agent_license,
    'Personal real estate license is required'],
  ['brokerage_office_name',
    state => !!state.brokerage_office_name,
    'Brokerage office name is required'],
  ['brokerage_license',
    state => !!state.brokerage_license,
    'Brokerage license is required'],
  ['service_areas',
    state => !!state.service_areas && state.service_areas.length > 0,
    'You must select at least one service area'],
  ['languages',
    state => !!state.languages && state.languages.length > 0,
    'You must select at least one language'],
  ['avatar_url',
    state => !!state.avatar || !!state.avatar_url,
    'You must upload a profile image'],
];
export type AgentFilter = {
  name: string,
  service_areas: CheckboxState,
  languages: CheckboxState
};