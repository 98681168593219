import {CreateTryulaAdminFields, TryulaAdmin, TryulaAdminState} from "../lib/object-control/admin";
import {
  useCreateTryulaAdminMutation,
  useDeleteTryulaAdminMutation,
  useGetAllTryulaAdminsQuery
} from "../services/tryulaApi";
import {useEffect, useState} from "react";
import {RedError} from "../lib/styled-components/RedError";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {DefaultPageLayout} from "../lib/layouts/DefaultPageLayout";
import {APIUserListPage} from "../lib/johncornish/components/v1/list/APIUserListPage";
import {BasicObjectUpdatingForm} from "../lib/johncornish/components/v1/form/BasicObjectUpdatingForm";
import {GreenCheckmark} from "../lib/styled-components/GreenCheckmark";
import {allow} from "../lib/auth/allow";
import {useCurrentUser} from "../features/auth/useUserData";
import {DeleteWithConfirmation} from "../lib/styled-components/DeleteWithConfirmation";

export function TryulaAdminsPage() {
  const {user} = useCurrentUser();
  const [adminState, setAdminState] = useState<TryulaAdminState>({
    email: ''
  })
  const {
    data: tryulaAdmins,
    error,
    isLoading,
  } = useGetAllTryulaAdminsQuery();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Admin'), []);

  return <>
    <PageTitle title="Tryula Admins"/>
    <div
      className="task relative flex justify-between rounded-sm border border-stroke bg-white p-5 shadow-default flex-col gap-5">
      <div className="flex flex-col gap-5">
        <div className="flex flex-col md:flex-row">
          <PageTitle title="Create Tryula Admin"/>
          <BasicObjectUpdatingForm
            resourceName={"admin"}
            state={adminState}
            setState={setAdminState}
            fieldsWithLabels={CreateTryulaAdminFields}
            useMutator={useCreateTryulaAdminMutation}
            validate={[
              ['email', state => !!state.email, 'Email is required']
            ]}
            saveText={"Create Tryula Admin"}
            saveClassName={"ml-1 mt-2 bg-[#27AAE1] hover:bg-blue-500 text-white font-bold py-1 px-4 rounded"}
            errorElement={RedError}
            updateSuccessElement={GreenCheckmark}/>
        </div>
      </div>
    </div>
    <APIUserListPage
      items={tryulaAdmins}
      error={error}
      isLoading={isLoading}
      resourcePlural="Tryula admins"
      resourceSingular="tryulaAdmin"
      pageLayoutComponent={DefaultPageLayout}
      itemComponent={TryulaAdminElement}
      emptyResultComponent={() => <p>No Tryula admins found.</p>}
    />
  </>;
}

const TryulaAdminElement = ({tryulaAdmin}: { tryulaAdmin: TryulaAdmin }) => {
  const [deleteTryulaAdmin] = useDeleteTryulaAdminMutation();
  const [deleteError, setDeleteError] = useState('');

  return <div data-testid="tryula-admin" className="grid grid-cols-3">
    <div className="col-span-1">
    <span data-testid="tryula-admin-name" className="break-words">
      {tryulaAdmin.email}
    </span>
    </div>

    <div className="col-span-2 items-end">
      <DeleteWithConfirmation
        dataObject={tryulaAdmin}
        uniqueIdentifier={tryulaAdmin.email}
        deleteMutator={deleteTryulaAdmin}
        setDeleteError={setDeleteError}/>
    </div>
    {!!deleteError && <RedError>{deleteError}</RedError>}
  </div>
};
