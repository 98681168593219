import {CheckboxState, FilterState} from "./types";
import {isChecked} from "../checkbox-selection/toggleViewerAndHandler";
import {excludeBySelection, excludeByString} from "./utils";

const filterIsEmptyOrAllChecked = (filterValue: string | CheckboxState) => {
  if (typeof filterValue === 'object') {
    return isChecked('All', filterValue);
  }
  return filterValue === '' || filterValue === 'All';
};

const isDate = (dateStr: string): [boolean, Date] => {
  const asDate = new Date(dateStr);
  return [!isNaN(asDate.getDate()), !!asDate ? asDate : new Date()];
};

function excludeByDate(date: Date, filter: string) {
  if (filter === 'All') {
    return false;
  }

  const matcher = /([<>])([0-9]+) (days)/;
  const match = filter.match(matcher);
  if (!!match) {
    const [, comparison, num, timeUnit] = match as [any, string, string, string];
    const todayMinusDays = new Date();
    if (comparison === '>' && timeUnit === 'days') {
      todayMinusDays.setDate(todayMinusDays.getDate() - Number(num))
      return !(todayMinusDays > date);
    }
  }

  return true;
}

export function predicateFrom(filter: FilterState | undefined) {
  if (!filter) {
    return () => true
  }

  return (item: Object) => {
    const itemAsData = item as { [k: string]: string | string[]; };
    for (const [stateField, filterValue] of Object.entries(filter)) {
      if ((!(stateField in item) || !itemAsData[stateField]) && !stateField.includes('_or_')) {
        if (!filterIsEmptyOrAllChecked(filterValue)) {
          return false;
        }
        continue;
      }

      if (typeof filterValue === 'object') {
        if (excludeBySelection([itemAsData[stateField]].flat(), filterValue)) {
          return false;
        }
        continue;
      }

      const [fieldIsDate, asDate] = isDate(itemAsData[stateField] as string)
      if (fieldIsDate) {
        if (excludeByDate(asDate, filterValue as string)) {
          return false;
        }

        continue;
      }

      let atLeastOneFieldMatches = false;
      for (const orableStateField of stateField.split('_or_')) {
        if (!excludeByString(itemAsData[orableStateField] as string, filterValue as string)) {
          atLeastOneFieldMatches = true;
        }
      }
      if (!atLeastOneFieldMatches) {
        return false;
      }
    }

    return true;
  };
}