import {useCurrentUser} from "../features/auth/useUserData";
import {useEffect, useState} from "react";
import {OptOutAgentRequest} from "../lib/object-control/agent";
import {allow} from "../lib/auth/allow";
import {useUpdateAgentMutation} from "../services/tryulaApi";
import {BasicLoadingElement} from "../lib/johncornish/components/v1/form/BasicLoadingElement";
import {RedError} from "../lib/styled-components/RedError";
import {createSubmitHandler} from "../lib/johncornish/components/v1/form/createSubmitHandler";
import {RedButton} from "../lib/styled-components/RedButton";
import {ContentHome} from "../config/siteSettings";
import {setCredentials} from "../features/auth/userDataSlice";
import {useDispatch} from "react-redux";
import {BlueButton} from "../lib/styled-components/BlueButton";

export function AgentOptOutPage() {
  const {user} = useCurrentUser();
  const dispatch = useDispatch();

  const [agentState, setAgentState] = useState<OptOutAgentRequest>({
    id: user?.agent?.id || 0,
    opt_out: false
  });
  const [updateError, setUpdateError] = useState('');
  const [updateAgent, {isLoading}] = useUpdateAgentMutation();
  const [requestingOptOut, setRequestingOptOut] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Agent'), []);
  if (!user?.agent?.id) {
    return null;
  }


  const handleOptOut = createSubmitHandler({
    mutator: updateAgent,
    setMutateError: setUpdateError,
    state: agentState,
    onSuccess: () => {
      dispatch(setCredentials({user: null, token: null}));
      window.location.assign(ContentHome);
    }
  });

  const showOptOutConfirmation = requestingOptOut && agentState.opt_out;
  return <div
    className="panelWhite task relative flex justify-between rounded-sm border border-stroke bg-white p-7 shadow-default gap-5">
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-x-8 gap-y-5">
        <a
          data-testid="back-to-edit-profile"
          className="block text-[#27AAE1] hover:underline px-1"
          href={`/agent/${user.agent.id}`}>
          Back to Edit Profile
        </a>

        <label className="block mb-2 mt-3" data-testid="opt-out">
          <input className="me-2"
                 data-testid="agent-opt_out"
                 type="checkbox"
                 checked={agentState.opt_out}
                 onChange={event => {
                   setAgentState(prevState => {
                     setRequestingOptOut(false);
                     return {...prevState, opt_out: event.target.checked};
                   });
                 }}/>

          I choose to opt out and remove myself from Tryula.com
        </label>

        <strong>
          Opting out will stop your profile from being searchable or listed on Tryula.com and end all communication
          including Twilio texts.
        </strong>

        <div className="block w-full">
          {showOptOutConfirmation || <button
            data-testid="request-opt-out"
            disabled={!agentState.opt_out}
            className="text-white bg-red-500 disabled:hover:cursor-not-allowed hover:bg-red-700 py-2 px-4 rounded"
            onClick={() => setRequestingOptOut(true)}>
            Opt out
          </button>}

          {showOptOutConfirmation && <div className="space-x-1">
            <p className="block mb-2">Are you sure?</p>
            <RedButton
              data-testId="yes-opt-out"
              onClick={handleOptOut}>
              Yes
            </RedButton>
            <BlueButton
              data-testId="no-dont-opt-out"
              onClick={() => {
                setRequestingOptOut(false);
                setAgentState(prevState => ({
                  ...prevState,
                  opt_out: false
                }));
              }}>
              No
            </BlueButton>
          </div>}

          {isLoading && <BasicLoadingElement/>}
          {!!updateError && <RedError>{updateError}</RedError>}
        </div>
      </div>
    </div>
  </div>;
}