import {useCurrentUser} from "../features/auth/useUserData";
import {useEffect, useState} from "react";
import {allow} from "../lib/auth/allow";
import {PageTitle} from "../lib/johncornish/components/v1/screen/PageTitle";
import {useCreateTransactionMutation, useGetAgentByPowerformIdQuery} from "../services/tryulaApi";
import {RedError} from "../lib/styled-components/RedError";
import {GreenCheckmark} from "../lib/styled-components/GreenCheckmark";
import {BasicObjectUpdatingForm} from "../lib/johncornish/components/v1/form/BasicObjectUpdatingForm";
import {ManualTransactionState} from "../lib/object-control/transactionData";

export function CreateTransactionManuallyPage() {
  const [transactionState, setTransactionState] =
    useState<ManualTransactionState>({
      agent_powerform_id: '',
      customer_email: '',
      envelope_id: '',
      num_transactions: 1
    });

  const {user} = useCurrentUser();

  const {
    data: agent,
    error,
    isLoading,
    isFetching
  } = useGetAgentByPowerformIdQuery(transactionState.agent_powerform_id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(allow(user, 'Admin', 'TryulaAdmin'), []);

  return <>
    <PageTitle title="New Transaction"/>
    <div
      className="task relative flex justify-between rounded-sm border border-stroke bg-white p-5 shadow-default flex-col gap-5">
      <p data-testid="agent-search-result" className="block">
        {!!error && !!transactionState.agent_powerform_id && 'Agent not found'}
        {(isLoading || isFetching) && '...'}
        {!error && !!agent && <>{agent.first_name} {agent.last_name}</>}
      </p>
      <BasicObjectUpdatingForm
        resourceName={"transaction"}
        state={transactionState}
        setState={setTransactionState}
        fieldsWithLabels={[
          ['Powerform Id', 'agent_powerform_id', {className: "rounded border border-stroke bg-[#eff4fb] p-1 font-normal text-black focus:border-primary focus-visible:outline-none"}],
          ['Customer Email', 'customer_email', {className: "rounded border border-stroke bg-[#eff4fb] p-1 font-normal text-black focus:border-primary focus-visible:outline-none"}],
          ['Envelope Id', 'envelope_id', {className: "rounded border border-stroke bg-[#eff4fb] p-1 font-normal text-black focus:border-primary focus-visible:outline-none"}],
          ['Number of transactions', 'num_transactions', {className: "rounded border border-stroke bg-[#eff4fb] p-1 font-normal text-black focus:border-primary focus-visible:outline-none"}]
        ]}
        useMutator={useCreateTransactionMutation}
        validate={[
          ['agent_powerform_id', state => !!state.agent_powerform_id, 'Powerform Id is required'],
          ['customer_email', state => !!state.customer_email, 'Customer email is required'],
          ['envelope_id', state => !!state.envelope_id, 'Envelope Id is required'],
          ['num_transactions', state => !isNaN(state.num_transactions) && state.num_transactions > 0, 'Number of transactions must be valid'],
        ]}
        saveText={"Create Transaction"}
        saveClassName={"ml-1 mt-2 bg-[#27AAE1] hover:bg-blue-500 text-white font-bold py-1 px-4 rounded"}
        errorElement={RedError}
        updateSuccessElement={GreenCheckmark}/>

      <p></p>
    </div>
  </>
    ;
}
