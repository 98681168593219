import {TwoChildren} from "../johncornish/components/v1/form/types";


export const TransactionColumn = ({children}: { children: any }) =>
  <div
    data-testid="transaction-column"
    className="col-span-1">
    {children}
  </div>;

export const TransactionRow = ({children}: TwoChildren) => {
  const [label, field] = children;
  return <div className="mb-2 grid grid-cols-1">
    <label className="font-semibold text-[#27aae1]">
      {label}
    </label>

    <div className="flex space-x-8">
      {field}
    </div>
  </div>;
};