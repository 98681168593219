import {fill, zipObject} from "lodash";
import {CheckboxState, FilterFieldConfig, FilterState} from "./types";
import {cloneElement, isValidElement, ReactNode} from "react";
import {isChecked} from "../checkbox-selection/toggleViewerAndHandler";

export const allValuesSelected =
  (availableValues: string[]) =>
    zipObject(['All', ...availableValues], fill(Array(availableValues.length + 1), true));

export const withAvailableAndChosen =
  (availableValues: { name: string }[], existingChosenValues: string[]) => {
    const baseWithNoneChosen = zipObject(
      ['All', ...availableValues.map((v: { name: string }) => v.name)],
      fill(Array(availableValues.length + 1),
        false));

    for (const existingChosenValue of existingChosenValues) {
      baseWithNoneChosen[existingChosenValue] = true;
    }

    if (existingChosenValues.length === Object.keys(baseWithNoneChosen).length - 1) {
      baseWithNoneChosen['All'] = true;
    }

    return baseWithNoneChosen;
  };

export function getChildWithFilter(child: ReactNode, filter: FilterState) {
  if (isValidElement(child)) {
    // @ts-ignore
    return cloneElement(child, {filter});
  }
  return null;
}

export function startingFilterState(fields: FilterFieldConfig[]) {
  const stateKeys = fields.map(([, stateField]) => stateField);
  const stateValues = fields.map(
    ([, , options]) => {
      if (options?.checkboxSelection) {
        return options.checkboxSelection;
      }

      if (options?.radioSelection) {
        return options.radioSelection[0];
      }

      return '';
    });
  return zipObject(stateKeys, stateValues);
}

export function startingOpenState(fields: FilterFieldConfig[]) {
  const checkboxFields = fields.filter(([, , options]) => !!options?.checkboxSelection);
  const stateKeys = checkboxFields.map(([, stateField]) => stateField);
  const stateValues = checkboxFields.map(([, , options]) => !!options?.inlineOpen);
  return zipObject(stateKeys, stateValues);
}

export function excludeBySelection(values: string[], selectedValues: CheckboxState) {
  if (!isChecked('All', selectedValues) && !values) return true;

  if (isChecked('All', selectedValues)) return false;

  for (const value of values) {
    if (isChecked(value, selectedValues)) {
      return false;
    }
  }

  return true;
}

export const excludeByString = (value: string | null | undefined, filter: string | null | undefined) => {
  if (!value || !filter) {
    return !!filter;
  }
  return !value.toLowerCase().includes(filter.toLowerCase());
};